<template>
    <div style="position:relative;">
        <div>
            <table class="logtable">
                <thead>
                    <tr>
                        <th class="rownum"></th>
                        <th>LOG ID</th>
                        <th>タイムスタンプ</th>
                        <th>操作</th>
                        <th>原価ID</th>
                        <th>計上済</th>
                        <th>原価部署</th>
                        <th>計上日</th>
                        <th>原価金額</th>
                        <th>消費税率</th>
                        <th>備考</th>
                        <th>登録アカウント</th>
                        <th>登録日時</th>
                        <th>変更アカウント</th>
                        <th>変更日時</th>
                        <th>Ver.</th>
                    </tr>
                </thead>
                <tbody v-for="(d, ix) in payload_data" :key="d.log_id">
                    <tr>
                        <th class="rownum">
                            {{ ix + 1 }}
                        </th>
                        <td>
                            {{ d.log_id }}
                        </td>
                        <td>
                            {{ d.log_tmsp | datetime }}
                        </td>
                        <td>
                            {{ viewcud(d.cud) }}
                        </td>
                        <td>
                            {{ d.costofsales_id }}
                        </td>
                        <td :class="modclass(d, 'record_flag')">
                            {{ d.record_flag | flag }}
                        </td>
                        <td :class="modclass(d, 'costofsales_department_id')">
                            <span class="id"
                                >{{ d.costofsales_department_id }}
                            </span>
                            <span class="code">{{
                                d.costofsales_department_code
                            }}</span>
                            {{ d.costofsales_department_name_short }}
                        </td>
                        <td :class="modclass(d, 'record_date', 'date')">
                            {{ d.record_date | date }}
                        </td>

                        <td
                            :class="[
                                'amount',
                                modclass(d, 'costofsales_amount')
                            ]"
                        >
                            {{ d.costofsales_amount | amount }}
                        </td>
                        <td :class="['amount', modclass(d, 'tax_rate')]">
                            {{ d.tax_rate }} %
                        </td>
                        <td :class="modclass(d, 'costofsales_note')">
                            {{ d.costofsales_note }}
                        </td>
                        <td>
                            {{ d.create_account_id }}
                        </td>
                        <td>
                            {{ d.create_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.update_account_id }}
                        </td>
                        <td>
                            {{ d.update_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.row_version }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import LogListMixin from "@/views/LogListMixin.vue";

export default {
    name: "CostofsalesLogList",
    mixins: [LogListMixin],
    model: {
        prop: "obj"
    },
    props: {
        obj: Object
    },
    data: () => ({
        payload_data: []
    }),
    methods: {
        execute: function() {
            this.payload_data = [];
            let url = "";
            if (this.obj.costofsales_id) {
                url = this.makeurl(
                    "log",
                    "cos",
                    this.obj.opportunity_id,
                    this.obj.salesorder_id,
                    this.obj.purchaseorder_id,
                    this.obj.costofsales_id
                );
            } else {
                url = this.makeurl(
                    "log",
                    "cos",
                    this.obj.opportunity_id,
                    this.obj.salesorder_id,
                    this.obj.purchaseorder_id
                );
            }
            this.readDataToArray(url, dlist => {
                this.settingPre(dlist, "costofsales_id");
                this.payload_data = dlist;
            });
        }
    },
    created: async function() {
        this.execute();
    }
};
</script>

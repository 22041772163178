<template>
    <div class="po-edit-panel">
        <div class="header content">
            <div class="item">
                <div class="title">案件</div>
                <span class="value">
                    <span class="id">{{ op.opportunity_id }}</span>
                    {{ op.opportunity_subject }}</span
                >
            </div>
            <div class="item">
                <div class="title">受注</div>
                <span class="value">
                    <span class="id">{{ so.salesorder_id }}</span>
                    {{ so.salesorder_subject }}</span
                >
            </div>
        </div>
        <div class="form" v-if="isStep(0)">
            <div class="po-edit-form">
                <div class="item po-sbj">
                    <OpTextField
                        label="発注件名"
                        v-model.trim="d.purchaseorder_subject"
                        maxlength="80"
                        name="purchaseorder_subject"
                        required
                        @changed-valid="changedValid"
                        ref="purchaseorderSubject"
                    />
                </div>
                <div class="item posts">
                    <OpDivSelector
                        label="発注ステータス"
                        v-model="d.purchaseorder_status"
                        :divlist="divs['posts']"
                        name="purchaseorder_status"
                        required
                        @changed-valid="changedValid"
                        divViewType="status"
                    />
                </div>
                <div class="item po-div">
                    <OpDivSelector
                        label="発注区分"
                        v-model="d.purchaseorder_div"
                        :divlist="divs['po']"
                        name="purchaseorder_div"
                        @changed-valid="changedValid"
                        required
                    />
                </div>
                <div class="item po-cust">
                    <OpSelectCustomer
                        label="発注先"
                        v-model="d.purchaseorder_customer"
                        required
                        name="purchaseorder_customer_id"
                        :referenceDate="d.delivery_date"
                        @input="inputedCustomer"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item po-emp">
                    <OpSelectEmployee
                        label="発注担当社員"
                        v-model.trim="d.purchaseorder_employee"
                        maxwidth="40em"
                        name="purchaseorder_employee"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item po-amt">
                    <OpNumberTextField
                        label="発注金額"
                        v-model.trim="d.purchaseorder_amount"
                        max="999999999999"
                        min="0"
                        name="purchaseorder_amount"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item po-date">
                    <OpDateTextField
                        label="発注日"
                        v-model.trim="d.purchaseorder_date"
                        name="purchaseorder_date"
                        @changed-valid="changedValid"
                    />
                </div>

                <div class="item ord-num">
                    <div class="title">発注書番号</div>
                    <div class="items">
                        <OpCheckbox
                            label="自動採番"
                            v-model="order_number_auto_flag"
                            name="order_number_auto_flag"
                        />
                        <OpNumberTextField
                            label="版"
                            v-model="d.order_version"
                            max="99"
                            min="0"
                            name="order_version"
                            :disabled="!order_number_auto_flag"
                            @changed-valid="changedValid"
                        />
                        <OpTextField
                            v-model.trim="d.order_number"
                            maxlength="20"
                            pattern="half"
                            name="order_number"
                            :disabled="order_number_auto_flag"
                            @changed-valid="changedValid"
                        />
                    </div>
                </div>

                <div class="item dlv-date">
                    <OpDateTextField
                        label="納品日"
                        v-model.trim="d.delivery_date"
                        name="delivery_date"
                        required
                        @changed-valid="changedValid"
                    />
                    <div v-if="d.cos_count > 1" class="note warning">
                        原価計上日の自動更新対象外
                    </div>
                </div>

                <div class="item inv-num">
                    <OpTextField
                        label="請求書番号"
                        v-model.trim="d.invoice_number"
                        maxlength="20"
                        pattern="half"
                        name="invoice_number"
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('po-p')"
                        :disabled="d.invoice_receipt_div == '9'"
                    />
                </div>
                <div class="item invrcpt">
                    <OpDivSelector
                        label="請求書受領区分"
                        v-model="d.invoice_receipt_div"
                        :divlist="divs['invrcpt']"
                        name="invoice_receipt_div"
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('po-p')"
                    />
                </div>
                <div class="item pmt-div">
                    <OpDivSelector
                        label="支払区分"
                        v-model="d.payment_div"
                        :divlist="divs['pmt']"
                        name="payment_div"
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('po-p')"
                    />
                </div>
                <div class="item pmt-date">
                    <OpDateTextField
                        label="支払日"
                        v-model.trim="d.payment_date"
                        name="payment_date"
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('po-p')"
                        :disabled="d.payment_div == '9'"
                        :required="d.payment_div == '1'"
                    />
                    <div class="note">
                        {{ descPaymentTerm() }}
                    </div>
                </div>
                <div class="item tax-rate">
                    <OpNumberTextField
                        label="消費税"
                        v-model="d.tax_rate"
                        max="100"
                        min="0"
                        name="tax_rate"
                        suffix="%"
                        suffixwidth="1em"
                        :decimals="2"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item po-note">
                    <OpTextField
                        label="発注備考"
                        v-model="d.purchaseorder_note"
                        name="purchaseorder_note"
                        maxlength="4000"
                        type="textarea"
                        inputStyle="height:240px;"
                        @changed-valid="changedValid"
                    />
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>

        <div v-if="isStep(1)">
            <table class="confirm">
                <!-- <tr>
                    <th>発注ID</th>
                    <td>
                        <div v-if="mode == 'update'">
                            {{ d.purchaseorder_id }}
                        </div>
                        <div v-else>
                            登録時に採番されます
                        </div>
                    </td>
                </tr> -->
                <tr>
                    <th>発注件名</th>
                    <td>{{ d.purchaseorder_subject }}</td>
                </tr>
                <tr>
                    <th>発注ステータス</th>
                    <td>
                        <span class="value">
                            <span class="code">{{
                                d.purchaseorder_status
                            }}</span>
                            {{ divname("posts", d.purchaseorder_status) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>発注区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.purchaseorder_div }}</span>
                            {{ divname("po", d.purchaseorder_div) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>発注先</th>
                    <td>
                        <span class="value">
                            <span class="id">{{
                                d.purchaseorder_customer.customer_id
                            }}</span>
                            <span class="code">{{
                                d.purchaseorder_customer.customer_code
                            }}</span>
                            {{ d.purchaseorder_customer.customer_name_long }}
                        </span>
                    </td>
                </tr>

                <tr>
                    <th>発注担当者</th>
                    <td>
                        <span class="value">
                            <span class="id">{{
                                d.purchaseorder_employee.employee_id
                            }}</span>
                            {{ d.purchaseorder_employee.employee_name_long }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>発注金額</th>
                    <td>{{ d.purchaseorder_amount | amount }}</td>
                </tr>

                <tr>
                    <th>発注日</th>
                    <td>{{ d.purchaseorder_date | date }}</td>
                </tr>
                <tr>
                    <th>納品日</th>
                    <td>{{ d.delivery_date | date }}</td>
                </tr>

                <tr>
                    <th>発注書版</th>
                    <td>{{ d.order_version }}</td>
                </tr>
                <tr>
                    <th>発注書番号</th>
                    <td>{{ d.order_number }}</td>
                </tr>
                <tr>
                    <th>請求書受領区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{
                                d.invoice_receipt_div
                            }}</span>
                            {{ divname("invrcpt", d.invoice_receipt_div) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>請求書番号</th>
                    <td>{{ d.invoice_number }}</td>
                </tr>

                <tr>
                    <th>支払区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.payment_div }}</span>
                            {{ divname("pmt", d.payment_div) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>支払日</th>
                    <td>{{ d.payment_date | date }}</td>
                </tr>
                <tr>
                    <th>消費税率</th>
                    <td>{{ d.tax_rate }} %</td>
                </tr>
                <tr>
                    <th>発注備考</th>
                    <td class="ponote">{{ d.purchaseorder_note }}</td>
                </tr>
            </table>

            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click.once="updateData" v-if="mode == 'update'">
                        登録
                    </button>
                    <button @click.once="addData" v-else>
                        追加
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message" v-if="mode == 'create'">
                発注 (ID : {{ d.purchaseorder_id }}) を登録しました。
            </div>
            <div class="message" v-if="mode == 'update'">
                発注 (ID : {{ d.purchaseorder_id }}) の情報を修正しました。
                <div v-if="msgcode == 2001">
                    <small
                        >原価の計上日および原価金額も更新しました。ご確認ください。</small
                    >
                </div>
                <div v-if="msgcode == 2002" class="warning">
                    <small
                        >⚠ 原価が複数件存在するため原価の計上日および原価金額は更新しておりません。</small
                    >
                </div>
                <div v-if="msgcode == 3001" class="warning">
                    <small
                        >「発注金額」と原価の「原価金額合計」が一致していません。<br />
                        「発注金額」または原価の「原価金額」修正してください。</small
                    >
                </div>
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <OpMessageModal
            v-if="showNotModified"
            title="メッセージ"
            @close="showNotModified = false"
        >
            変更箇所が見つからないため処理を中止しました。
        </OpMessageModal>
    </div>
</template>

<script>
import OpSelectCustomer from "@/components/OpSelectCustomer.vue";
import OpSelectEmployee from "@/components/OpSelectEmployee.vue";
import OpMessageModal from "@/components/OpMessageModal.vue";
import EditorMixin from "@/views/EditorMixin.vue";

// const axios = require("axios");

export default {
    name: "PurchaseorderEditor",
    mixins: [EditorMixin],
    model: {
        prop: "model"
    },
    components: {
        OpSelectCustomer,
        OpSelectEmployee,
        OpMessageModal
    },
    props: {
        model: Object,
        mode: String,
        so: Object,
        op: Object
    },
    data: function() {
        return {
            order_number_auto_flag: this.mode == "create",
            d: this.initModel(this.model),
            cdialog: {
                okbutton: "OK",
                cancelbutton: "キャンセル",
                message: "",
                step: 0,
                execfunc: function() {}
            },
            inputed_order_number: this.model.order_number,
            msgcode: null,
            showNotModified: false
        };
    },
    watch: {
        order_number_auto_flag: function(val) {
            if (val) {
                this.inputed_order_number = this.d.order_number;
                let a = this.d.order_version;
                if (
                    this.mode == "update" &&
                    a !== null &&
                    a !== undefined &&
                    a !== ""
                ) {
                    this.d.order_number =
                        "P" + this.d.purchaseorder_id + "-" + a;
                } else {
                    this.d.order_number = "";
                }
            } else {
                this.d.order_number = this.inputed_order_number;
            }
        },
        "d.order_version": function(val) {
            if (this.order_number_auto_flag) {
                if (
                    this.mode == "update" &&
                    val !== null &&
                    val !== undefined &&
                    val !== ""
                ) {
                    this.d.order_number =
                        "E" + this.d.purchaseorder_id + "-" + val;
                } else {
                    this.d.order_number = "";
                }
            }
        },
        "d.delivery_date": function(val) {
            if (this.d.payment_div == "0") {
                if (
                    this.d.purchaseorder_customer &&
                    this.d.purchaseorder_customer.payment_day
                ) {
                    let cust = this.d.purchaseorder_customer;
                    this.d.payment_date = this.calcDepositPaymentDate(
                        val,
                        cust.payment_cutoff_day,
                        cust.payment_term,
                        cust.payment_day,
                        cust.payment_later_date_flag
                    );
                }
            }
        }
    },

    computed: {},
    methods: {
        initModel: function(model) {
            let ret = { ...model };
            ret.purchaseorder_employee = {
                employee_id: ret.purchaseorder_employee_id,
                employee_name_long: ret.purchaseorder_employee_name_long
            };
            ret.purchaseorder_customer = {
                customer_id: ret.purchaseorder_customer_id,
                customer_code: ret.purchaseorder_customer_code,
                customer_name_long: ret.purchaseorder_customer_name_long,
                customer_name_short: ret.purchaseorder_customer_name_short,
                payment_cutoff_day: ret.po_pmt_co_day,
                payment_day: ret.po_pmt_day,
                payment_term: ret.po_pmt_term,
                payment_later_date_flag: ret.po_pmt_later_date_flg
            };

            if (!ret.salesorder_id) {
                ret.salesorder_id = this.so.salesorder_id;
                ret.invoice_receipt_div = 0;
                ret.payment_div = 0;
                ret.purchaseorder_subject = this.so.salesorder_subject;
                ret.purchaseorder_status = this.so.salesorder_status;
                ret.order_version = 1;
                ret.purchaseorder_employee = this.getCurrentEmployee();
            }
            if (!ret.tax_rate) {
                ret.tax_rate = this.getCurrentTaxRate();
            }
            return ret;
        },
        doConfirm: function() {
            this.msgcode = null;
            this.setStep(1);
        },
        close: function() {
            this.$emit("fin");
        },
        addData: function() {
            this.d.purchaseorder_employee_id = this.d.purchaseorder_employee.employee_id;
            this.d.purchaseorder_customer_id = this.d.purchaseorder_customer.customer_id;
            this.postData(this.makeurl("po"), this.d, dt => {
                this.d = dt;
                this.$emit("poUpdated", dt);
                this.setStep(2);
            });
        },
        updateData: function() {
            this.d.purchaseorder_employee_id = this.d.purchaseorder_employee.employee_id;
            this.d.purchaseorder_customer_id = this.d.purchaseorder_customer.customer_id;
            this.putData(
                this.makeurl("po", this.d.purchaseorder_id),
                this.d,
                (dt, response) => {
                    if (dt.purchaseorder_id) {
                        this.msgcode = response.headers["mop-message-code"];
                        this.d = dt;
                        this.$emit("poUpdated", dt);
                        this.setStep(2);
                    } else {
                        this.showNotModified = true;
                        this.setStep(0);
                    }
                }
            );
        },
        divname: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentlong;
            }
            return ret;
        },
        descPaymentTerm: function() {
            let ret = "";
            if (
                this.d.purchaseorder_customer &&
                this.d.purchaseorder_customer.payment_day
            ) {
                ret += "※";
                let cust = this.d.purchaseorder_customer;
                let cday = cust.payment_cutoff_day;

                if (cday == 31) {
                    ret += "月末締";
                } else {
                    ret += "毎月" + cday + "日締";
                }
                ret += " / ";
                let val = cust.payment_term;
                if (val === 0 || val === "0") {
                    ret += "当月";
                } else if (val == 1) {
                    ret += "翌月";
                } else if (val == 2) {
                    ret += "翌々月";
                } else {
                    ret += val + "ヵ月後の";
                }
                let pday = cust.payment_day;
                if (pday == 31) {
                    pday = "末";
                }
                ret += pday + "日支払";
            }
            return ret;
        },
        inputedCustomer: function(val) {
            if (this.d.payment_div == "0") {
                if (val && val.payment_day) {
                    let cust = val;
                    this.d.payment_date = this.calcDepositPaymentDate(
                        this.d.delivery_date,
                        cust.payment_cutoff_day,
                        cust.payment_term,
                        cust.payment_day,
                        cust.payment_later_date_flag
                    );
                } else {
                    this.d.payment_date = null;
                }
            }
        }
    },
    created: async function() {
        await this.loaddivs("po", "pmt", "invrcpt", "posts");
        this.$refs.purchaseorderSubject.focus();
    }
};
</script>

<style scoped>
.po-edit-panel {
    position: relative;
    min-width: 450px;
    padding: 0;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}
/* --- header --- */
.po-edit-panel .header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--main-context-bg-color);
    filter: drop-shadow(var(--layerd-shadow));
    margin-bottom: 5px;
}

/* --- form --- */
.po-edit-form {
    position: relative;
    display: grid;

    grid-template-columns: 250px 100px 100px 150px 200px;
    grid-template-areas:
        "po-sbj        po-sbj      po-sbj      po-sbj      po-sbj"
        "posts            posts       posts       posts       posts"
        "po-div            po-cust     po-cust     po-cust     po-cust"
        "po-emp           po-amt      po-amt      po-date     dlv-date"
        "ord-num        ord-num     ord-num     po-note     po-note"
        "invrcpt         inv-num     inv-num     po-note     po-note"
        "pmt-div        pmt-date    pmt-date    po-note    po-note";
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    align-items: initial;
    z-index: 1;
    padding: 5px;
}

.po-edit-form .form-field {
    width: 100%;
}

.po-edit-form .po-sbj {
    grid-area: po-sbj;
}

.po-edit-form .posts {
    grid-area: posts;
}
.po-edit-form .po-div {
    grid-area: po-div;
}
.po-edit-form .po-cust {
    grid-area: po-cust;
}
.po-edit-form .po-emp {
    grid-area: po-emp;
}
.po-edit-form .po-amt {
    grid-area: po-amt;
}
.po-edit-form .po-date {
    grid-area: po-date;
}
/* .po-edit-form .ord-ver {
    grid-area: ord-ver;
} */

/* .po-edit-form .ord-num .items .form-field:first-child {
    width: auto;
    padding-right: 10px;
}
.po-edit-form .ord-num .items {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: -1rem;
}
*/
.po-edit-form .ord-num {
    grid-area: ord-num;
}

.po-edit-form .ord-num .items .form-field {
    width: auto !important;
    padding: 0 10px;
}
.po-edit-form .ord-num .items {
    display: flex;
    flex-flow: row;
    align-items: top;
    justify-content: flex-start;
    margin-top: 0rem;
}
.po-edit-form .ord-num .items .form-field:first-child {
    width: auto;
    padding-right: 10px;
    padding-bottom: 0.5rem;
    align-self: center;
}

.po-edit-form .dlv-date {
    grid-area: dlv-date;
}
.po-edit-form .inv-num {
    grid-area: inv-num;
}
.po-edit-form .invrcpt {
    grid-area: invrcpt;
}
.po-edit-form .pmt-date {
    grid-area: pmt-date;
}
.po-edit-form .pmt-div {
    grid-area: pmt-div;
}
.po-edit-form .po-note {
    grid-area: po-note;
}
.ponote {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    line-height: 1.5em;
}
/* --- complete --- */
.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}
.complete .message .warning {
    color: red;
}
.item .note {
    padding-top: 5px;
    font-size: 0.75rem;
}
.item .warning {
    color: red;
}
/* .item .note:before {
    content: "※ ";
} */
</style>
<style>
.po-edit-form .statusValue {
    font-size: 1.25rem;
    width: 3rem;
    height: 2rem;
}
.confirm {
    min-width: 600px;
}
</style>

<template>
    <div class="costofsales-panel">
        <div class="panel-header">
            <div>
                <h3>
                    原価
                </h3>
                <MopEditButton @click="openPost" />
                <MopLogButton @click="openLog" />
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <th>原価ID</th>
                    <th>計上済</th>
                    <th>原価部署</th>
                    <th>計上日</th>
                    <th>原価金額</th>
                    <!-- <th>消費税率</th> -->
                    <th>備考</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="d in payload_data" :key="d.costofsales_id">
                    <td>{{ d.costofsales_id }}</td>
                    <td style="position :relative; overflow:visible;">
                        <ZumiStamp :width="24" v-if="d.record_flag" />
                    </td>
                    <td>{{ d.costofsales_department_name_short }}</td>
                    <td>{{ d.record_date | date }}</td>
                    <td class="amount">{{ d.costofsales_amount | amount }}</td>
                    <!-- <td class="amount">{{ d.tax_rate }} %</td> -->
                    <td class="note">{{ d.costofsales_note }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="amount">
                        原価金額合計 =
                    </td>
                    <td class="amount">
                        <span class="total">{{ amounttotal | amount }}</span>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="amount">
                        原価金額合計 - 発注金額 =
                    </td>
                    <td class="amount">
                        <span
                            :class="[
                                'total',
                                { 'amount-notmatch': amountdiff != 0 }
                            ]"
                        >
                            <span v-if="amountdiff > 0"> + </span>
                            {{ amountdiff | amount }}</span
                        >
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import MopEditButton from "@/components/buttons/MopEditButton.vue";
import MopLogButton from "@/components/buttons/MopLogButton.vue";
import ZumiStamp from "@/components/ZumiStamp.vue";

export default {
    name: "CostofsalesList",
    props: {
        po: Object,
        so: Object
    },
    components: {
        MopEditButton,
        MopLogButton,
        ZumiStamp
    },
    data: () => ({
        showEdit: false,
        showCreate: false,
        selectedData: null,
        payload_data: []
    }),
    watch: {
        "po.coslist": function(val) {
            this.payload_data = val;
        }
    },
    computed: {
        amounttotal: function() {
            let ret = 0;
            if (this.payload_data) {
                for (let d of this.payload_data) {
                    ret += Number.parseInt(d.costofsales_amount);
                }
            }
            return ret;
        },
        amountdiff: function() {
            let ret = this.amounttotal;
            ret -= this.po.purchaseorder_amount;
            return ret;
        },
        showSubCtrlMenu: function() {
            let ret = null;
            if (this.selectedData) {
                let ele = this.$store.state.clickedTarget;
                if (ele) {
                    let tg = ele.closest("div.sub-control");
                    if (
                        tg &&
                        tg.id ==
                            "costofsalessubcontrol" +
                                this.selectedData.costofsales_id
                    ) {
                        ret = this.selectedData;
                    }
                }
            }
            return ret;
        }
    },
    methods: {
        zumiStyle: function() {
            let dtop = Math.random() * 5;
            let dleft = Math.random() * 10;
            let ddeg = Math.random() * 45;
            ddeg -= 30;
            let ret = {
                position: "absolute",
                top: "calc(50% - 15px + " + dtop + "px)",
                left: "calc(50% - 17px + " + dleft + "px)",
                transformOrigin: "center",
                transform: "rotate(" + ddeg + "deg)"
            };
            console.log(ret);
            return ret;
        },
        openLog: function() {
            let state = this.$store.state.opportunityState.costofsalesLog;
            state.model = {
                opportunity_id: this.so.opportunity_id,
                salesorder_id: this.so.salesorder_id,
                purchaseorder_id: this.po.purchaseorder_id
            };
            state.show = true;
        },
        openPost: function() {
            let state = this.$store.state.opportunityState
                .costofsaleslistEditor;
            state.title = "原価編集";
            state.data = this.payload_data;
            state.po = this.po;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "create";
            state.show = true;
        },
        refresh: async function(dt) {
            this.$emit("beginLoad");
            if (dt instanceof Array) {
                this.payload_data = dt;
                this.po.coslist = dt;
            } else {
                // this.selectedData = dt;
                await this.load();
            }
            this.$emit("endLoad");
        },
        load: function() {
            return this.readDataToArray(
                this.makeurl("cos", { po: this.po.purchaseorder_id }),
                datalist => {
                    this.payload_data = datalist;
                    this.po.coslist = datalist;
                }
            );
        }
    },
    created: async function() {
        // this.d.salesorder_id = this.oppo.soid;
        // await this.loaddivs("op1", "op2", "op3", "op4", "op5");
        this.$emit("beginLoad");
        if (!this.po.ccoslist) {
            await this.load();
        } else {
            this.payload_data = this.po.coslist;
        }
        this.$emit("endLoad");
    }
};
</script>

<style scoped>
.costofsales-panel {
    position: relative;
    display: grid;
    grid-template-areas: "panel-header      table";
    grid-template-columns: 60px 1fr;
}
.costofsales-panel > .panel-header {
    width: 60px;
    text-align: center;
    grid-area: panel-header;
}
.costofsales-panel > table {
    grid-area: table;
}
.costofsales-panel > .amountdiff-msg {
    grid-area: msg;
}
.costofsales-panel > table > tbody > tr:last-child > td {
    border-bottom: 3px double var(--border-color);
}
.costofsales-panel > table > tfoot td {
    border-style: none;
}

.amount-notmatch {
    color: var(--error-text-color);
}
.total {
    display: inline-block;
    padding-left: 2em;
}

.costofsales-panel h3 {
    font-weight: normal;
    font-size: 1.2em;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
}

td.note {
    width: 25%;
    white-space: pre-wrap;
}

/* --- */
.sub-control .sub-control-menu {
    width: 140px;
}
.sub-control .sysinfo {
    font-size: 0.75em;
    text-align: left;
}
</style>

<template>
    <div style="position:relative;">
        <div>
            <table class="logtable">
                <thead>
                    <tr>
                        <th class="rownum"></th>
                        <th>LOG ID</th>
                        <th>タイムスタンプ</th>
                        <th>操作</th>
                        <th>案件ID</th>
                        <th>案件名</th>
                        <th>受注先</th>
                        <th>ユーザ</th>
                        <th>担当部署</th>
                        <th>商流(区分1)</th>
                        <th>経路(区分2)</th>
                        <th>科目(区分3)</th>
                        <th>区分4</th>
                        <th>区分5</th>
                        <th>商談備考</th>
                        <th>案件備考</th>
                        <th>登録アカウント</th>
                        <th>登録日時</th>
                        <th>変更アカウント</th>
                        <th>変更日時</th>
                        <th>Ver.</th>
                    </tr>
                </thead>
                <tbody v-for="(d, ix) in payload_data" :key="d.log_id">
                    <tr>
                        <th class="rownum">
                            {{ ix + 1 }}
                        </th>
                        <td>{{ d.log_id }}</td>
                        <td>
                            {{ d.log_tmsp | datetime }}
                        </td>
                        <td>
                            {{ viewcud(d.cud) }}
                        </td>
                        <td>
                            {{ d.opportunity_id }}
                        </td>
                        <td :class="modclass(d, 'opportunity_subject')">
                            {{ d.opportunity_subject }}
                        </td>
                        <td :class="modclass(d, 'salesorder_customer_id')">
                            <span class="id">{{
                                d.salesorder_customer_id
                            }}</span>
                            <span class="code">{{
                                d.salesorder_customer_code
                            }}</span>
                            {{ d.salesorder_customer_name_short }}
                        </td>
                        <td :class="modclass(d, 'user_customer_id')">
                            <span class="id">{{ d.user_customer_id }}</span>
                            <span class="code">{{ d.user_customer_code }}</span>
                            {{ d.user_customer_name_short }}
                        </td>

                        <td :class="modclass(d, 'opportunity_department_id')">
                            <span class="id">{{
                                d.opportunity_department_id
                            }}</span>
                            <span class="code">{{
                                d.opportunity_department_code
                            }}</span>
                            {{ d.opportunity_department_name_short }}
                        </td>
                        <td :class="modclass(d, 'opportunity_div1')">
                            <span class="code" v-if="d.opportunity_div1">{{
                                d.opportunity_div1
                            }}</span>
                            {{ viewdiv("op1", d.opportunity_div1) }}
                        </td>
                        <td :class="modclass(d, 'opportunity_div2')">
                            <span class="code" v-if="d.opportunity_div2">{{
                                d.opportunity_div2
                            }}</span>
                            {{ viewdiv("op2", d.opportunity_div2) }}
                        </td>
                        <td :class="modclass(d, 'opportunity_div3')">
                            <span class="code" v-if="d.opportunity_div3">{{
                                d.opportunity_div3
                            }}</span>
                            {{ viewdiv("op3", d.opportunity_div3) }}
                        </td>
                        <td :class="modclass(d, 'opportunity_div4')">
                            <span class="code" v-if="d.opportunity_div4">{{
                                d.opportunity_div4
                            }}</span>
                            {{ viewdiv("op4", d.opportunity_div4) }}
                        </td>
                        <td :class="modclass(d, 'opportunity_div5')">
                            <span class="code" v-if="d.opportunity_div5">{{
                                d.opportunity_div5
                            }}</span>
                            {{ viewdiv("op5", d.opportunity_div5) }}
                        </td>
                        <td :class="modclass(d, 'business_note')">
                            {{ d.business_note }}
                        </td>
                        <td :class="modclass(d, 'opportunity_note')">
                            {{ d.opportunity_note }}
                        </td>
                        <td>
                            {{ d.create_account_id }}
                        </td>
                        <td>
                            {{ d.create_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.update_account_id }}
                        </td>
                        <td>
                            {{ d.update_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.row_version }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import LogListMixin from "@/views/LogListMixin.vue";

export default {
    name: "OpportunityLogList",
    mixins: [LogListMixin],
    model: {
        prop: "op"
    },
    props: {
        op: Object
    },
    data: () => ({
        payload_data: []
    }),
    methods: {
        execute: function() {
            this.payload_data = [];
            this.readDataToArray(
                this.makeurl("op", this.op.opportunity_id, "log"),
                dlist => {
                    this.settingPre(dlist, "opportunity_id");
                    this.payload_data = dlist;
                }
            );
        }
    },
    created: async function() {
        await this.loaddivs("op1", "op2", "op3", "op4", "op5", "sosts");
        this.execute();
    }
};
</script>

<template>
    <div class="sales-panel">
        <div class="panel-header">
            <div>
                <h3>
                    売上
                </h3>
                <MopEditButton @click="openPost" />
                <MopLogButton @click="openLog" />
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>売上ID</th>
                    <th>売上部署</th>
                    <th>売上計上日</th>
                    <th>計上済</th>
                    <th>売上金額</th>
                    <!-- <th>消費税率</th> -->
                    <th>備考</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="d in payload_data" :key="d.sales_id">
                    <td>{{ d.sales_id }}</td>
                    <td>{{ d.sales_department_name_short }}</td>
                    <td>{{ d.record_date | date }}</td>
                    <td style="position :relative; overflow:visible;">
                        <ZumiStamp :width="24" v-if="d.record_flag" />
                    </td>
                    <td class="amount">{{ d.sales_amount | amount }}</td>
                    <!-- <td class="amount">{{ d.tax_rate }} %</td> -->
                    <td class="note">{{ d.sales_note }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="amount">
                        売上金額合計 =
                    </td>
                    <td class="amount">
                        <span class="total">{{ amounttotal | amount }}</span>
                    </td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4" class="amount">
                        売上金額合計 - 受注金額 =
                    </td>
                    <td class="amount">
                        <span
                            :class="[
                                'total',
                                { 'amount-notmatch': amountdiff != 0 }
                            ]"
                        >
                            <span v-if="amountdiff > 0"> + </span>
                            {{ amountdiff | amount }}</span
                        >
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
// import SalesEditor from "@/views/SalesEditor.vue";

import MopEditButton from "@/components/buttons/MopEditButton.vue";
import MopLogButton from "@/components/buttons/MopLogButton.vue";
import ZumiStamp from "@/components/ZumiStamp.vue";

export default {
    name: "SalesList",
    props: {
        so: Object,
        op: Object
    },
    components: {
        MopEditButton,
        MopLogButton,
        ZumiStamp
    },
    data: () => ({
        showEdit: false,
        showCreate: false,
        selectedData: null,
        payload_data: []
    }),
    watch: {
        "so.slslist": function(val) {
            this.payload_data = val;
        }
    },
    computed: {
        amounttotal: function() {
            let ret = 0;
            if (this.payload_data) {
                for (let d of this.payload_data) {
                    ret += Number.parseInt(d.sales_amount);
                }
            }
            return ret;
        },
        amountdiff: function() {
            let ret = this.amounttotal;
            ret -= this.so.salesorder_amount;
            return ret;
        },
        showSubCtrlMenu: function() {
            let ret = null;
            if (this.selectedData) {
                let ele = this.$store.state.clickedTarget;
                if (ele) {
                    let tg = ele.closest("div.sub-control");
                    if (
                        tg &&
                        tg.id == "salessubcontrol" + this.selectedData.sales_id
                    ) {
                        ret = this.selectedData;
                    }
                }
            }
            return ret;
        }
    },
    methods: {
        openLog: function() {
            let state = this.$store.state.opportunityState.salesLog;
            state.model = this.so;
            state.show = true;
        },
        openPost: function() {
            let state = this.$store.state.opportunityState.saleslistEditor;
            state.title = "売上編集";
            state.data = this.payload_data;
            state.so = this.so;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "create";
            state.show = true;
        },
        refresh: async function(dt) {
            this.$emit("beginLoad");
            // this.payload_data = [];
            if (dt instanceof Array) {
                this.payload_data = dt;
                this.so.slslist = dt;
            } else {
                // this.selectedData = dt;
                await this.load();
            }
            this.$emit("endLoad");
        },
        load: function() {
            return this.readDataToArray(
                this.makeurl("sls", { so: this.so.salesorder_id }),
                list => {
                    this.payload_data = list;
                    this.so.slslist = list;
                }
            );
        }
    },
    // updated: function() {
    //     this.$emit("endLoad");
    // },
    created: async function() {
        this.$emit("beginLoad");
        if (!this.so.slslist) {
            await this.load();
        } else {
            this.payload_data = this.so.slslist;
        }
        this.$emit("endLoad");
    }
};
</script>

<style scoped>
.sales-panel {
    position: relative;
    display: grid;
    grid-template-areas: "panel-header      table";
    grid-template-columns: 60px 1fr;
}
.sales-panel > .panel-header {
    width: 60px;
    text-align: center;
    grid-area: panel-header;
}
.sales-panel > table {
    grid-area: table;
}
.sales-panel > .amountdiff-msg {
    grid-area: msg;
}
.sales-panel > table > tbody > tr:last-child > td {
    border-bottom: 3px double var(--border-weak-color);
}
.sales-panel > table > tfoot td {
    border-style: none;
}

.amount-notmatch {
    color: var(--error-text-color);
}
.total {
    display: inline-block;
    padding-left: 2em;
}

.sales-panel h3 {
    font-weight: normal;
    font-size: 1.2em;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
}

td.note {
    width: 25%;
    white-space: pre-wrap;
}

/* --- */
.sub-control .sub-control-menu {
    width: 140px;
}
.sub-control .sysinfo {
    font-size: 0.75em;
    text-align: left;
}
</style>

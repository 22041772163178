<template>
    <div style="position:relative;">
        <div>
            <table class="logtable">
                <thead>
                    <tr>
                        <th class="rownum"></th>
                        <th>LOG ID</th>
                        <th>タイムスタンプ</th>
                        <th>操作</th>
                        <th>受注ID</th>
                        <th>受注件名</th>
                        <th>受注ステータス</th>
                        <th>取消理由区分</th>
                        <th>取消理由備考</th>
                        <th>見積書版</th>
                        <th>見積書番号</th>
                        <th>注文番号</th>
                        <th>受注担当社員</th>
                        <th>受注金額</th>
                        <th>消費税率</th>
                        <th>受注日</th>
                        <th>検収日</th>
                        <th>入金日</th>
                        <th>請求書番号</th>
                        <th>請求書発行済区分</th>
                        <th>入金済区分</th>
                        <th>受注備考</th>
                        <th>登録アカウント</th>
                        <th>登録日時</th>
                        <th>変更アカウント</th>
                        <th>変更日時</th>
                        <th>Ver.</th>
                    </tr>
                </thead>
                <tbody v-for="(d, ix) in payload_data" :key="d.log_id">
                    <tr>
                        <th class="rownum">
                            {{ ix + 1 }}
                        </th>
                        <td>
                            {{ d.log_id }}
                        </td>
                        <td>
                            {{ d.log_tmsp | datetime }}
                        </td>
                        <td>
                            {{ viewcud(d.cud) }}
                        </td>
                        <td>
                            {{ d.salesorder_id }}
                        </td>
                        <td :class="modclass(d, 'salesorder_subject')">
                            {{ d.salesorder_subject }}
                        </td>
                        <td :class="modclass(d, 'salesorder_status')">
                            {{ d.salesorder_status }}
                            <span class="stsname">{{
                                viewdiv("sosts", d.salesorder_status)
                            }}</span>
                        </td>
                        <td :class="modclass(d, 'cancel_div')">
                            <span class="code" v-if="d.cancel_div">{{
                                d.cancel_div
                            }}</span>
                            {{ viewdiv("can", d.cancel_div) }}
                        </td>

                        <td :class="modclass(d, 'cancel_note')">
                            {{ d.cancel_note }}
                        </td>
                        <td :class="modclass(d, 'estimate_version')">
                            {{ d.estimate_version }}
                        </td>
                        <td :class="modclass(d, 'estimate_number')">
                            {{ d.estimate_number }}
                        </td>
                        <td :class="modclass(d, 'order_number')">
                            {{ d.order_number }}
                        </td>
                        <td :class="modclass(d, 'salesorder_employee_id')">
                            <span class="code">{{
                                d.salesorder_employee_id
                            }}</span>
                            {{ d.salesorder_employee_name_long }}
                        </td>
                        <td
                            :class="[
                                'amount',
                                modclass(d, 'salesorder_amount')
                            ]"
                        >
                            {{ d.salesorder_amount | amount }}
                        </td>
                        <td :class="['amount', modclass(d, 'tax_rate')]">
                            {{ d.tax_rate }} %
                        </td>
                        <td :class="modclass(d, 'salesorder_date', 'date')">
                            {{ d.salesorder_date | date }}
                        </td>
                        <td :class="modclass(d, 'acceptance_date', 'date')">
                            {{ d.acceptance_date | date }}
                        </td>
                        <td :class="modclass(d, 'deposit_date', 'date')">
                            {{ d.deposit_date | date }}
                        </td>
                        <td :class="modclass(d, 'invoice_number')">
                            {{ d.invoice_number }}
                        </td>
                        <td :class="modclass(d, 'invoice_issued_div')">
                            <span class="code">
                                {{ d.invoice_issued_div }}</span
                            >
                            {{ viewdiv("invissued", d.invoice_issued_div) }}
                        </td>
                        <td :class="modclass(d, 'deposit_div')">
                            <span class="code"> {{ d.deposit_div }}</span>
                            {{ viewdiv("dep", d.deposit_div) }}
                        </td>
                        <td :class="modclass(d, 'salesorder_note')">
                            {{ d.salesorder_note }}
                        </td>
                        <td>
                            {{ d.create_account_id }}
                        </td>
                        <td>
                            {{ d.create_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.update_account_id }}
                        </td>
                        <td>
                            {{ d.update_timestamp | datetime }}
                        </td>
                        <td>
                            {{ d.row_version }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import LogListMixin from "@/views/LogListMixin.vue";

export default {
    name: "SalesorderLogList",
    mixins: [LogListMixin],
    model: {
        prop: "obj"
    },
    props: {
        obj: Object
    },
    data: () => ({
        payload_data: []
    }),
    methods: {
        execute: function() {
            let url = "";
            if (this.obj.salesorder_id) {
                url = this.makeurl(
                    "log",
                    "so",
                    this.obj.opportunity_id,
                    this.obj.salesorder_id
                );
            } else {
                url = this.makeurl("log", "so", this.obj.opportunity_id);
            }
            return this.readDataToArray(url, dlist => {
                this.settingPre(dlist, "salesorder_id");
                this.payload_data = dlist;
            });
        }
    },
    created: async function() {
        await this.loaddivs("sosts", "invissued", "dep", "can");
        await this.execute();
    }
};
</script>

<style scoped>
td {
    vertical-align: top;
    border-right-style: none;
    border-left-style: none;
}
td .title {
    font-size: 0.5em;
    display: block;
}
td .value {
    padding: 3px 0;
}
td .value .id {
    font-size: 0.75em;
}
td .value .id:after {
    content: " : ";
}
tbody:nth-child(2n + 1) {
    background: var(--odd-bg-color);
}
</style>

<template>
    <div class="so-edit-panel">
        <div class="header content">
            <div class="item opsbj">
                <div class="title">案件</div>
                <span class="value">
                    <span class="id">{{ op.opportunity_id }}</span>
                    {{ op.opportunity_subject }}</span
                >
            </div>
            <!-- <div class="item scust">
                <div class="title">受注先</div>
                <div class="value">
                    <span class="id">{{ op.salesorder_customer_id }}</span>
                    <span class="code">{{ op.salesorder_customer_code }}</span>
                    {{ op.salesorder_customer_name_long }}
                </div>
            </div>
            <div class="item opdept">
                <div class="title">案件担当部署</div>
                <div class="value">
                    <span class="id">{{ op.opportunity_department_id }}</span>
                    <span class="code">{{
                        op.opportunity_department_code
                    }}</span>
                    {{ op.opportunity_department_name_long }}
                </div>
            </div> -->
        </div>
        <div class="form" v-if="isStep(0)">
            <div class="so-edit-form">
                <div class="item so-sbj">
                    <OpTextField
                        label="受注件名"
                        v-model.trim="d.salesorder_subject"
                        maxlength="80"
                        name="salesorder_subject"
                        required
                        @changed-valid="changedValid"
                        ref="salesorderSubject"
                    />
                </div>
                <div class="item sosts">
                    <OpDivSelector
                        label="受注ステータス"
                        v-model="d.salesorder_status"
                        :divlist="divs['sosts']"
                        name="sosts"
                        required
                        @changed-valid="changedValid"
                        divViewType="status"
                    />
                </div>
                <div class="item can-div" v-if="isStatusZ">
                    <OpDivSelector
                        label="取消理由区分"
                        v-model="d.cancellation_div"
                        :divlist="divs['can']"
                        name="can"
                        :required="isStatusZ"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item can-note" v-if="isStatusZ">
                    <OpTextField
                        label="取消理由備考"
                        v-model.trim="d.cancellation_note"
                        maxlength="40"
                        name="cancellation_note"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item so-emp">
                    <OpSelectEmployee
                        label="受注担当社員"
                        v-model.trim="d.salesorder_employee"
                        maxlength="40"
                        name="salesorder_employee_id"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item so-amt">
                    <OpNumberTextField
                        label="受注金額"
                        v-model.trim="d.salesorder_amount"
                        max="999999999999"
                        min="0"
                        name="salesorder_amount"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item so-date">
                    <OpDateTextField
                        label="受注日"
                        v-model.trim="d.salesorder_date"
                        name="salesorder_date"
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item acpt-date">
                    <OpDateTextField
                        label="検収日"
                        v-model="d.acceptance_date"
                        name="acceptance_date"
                        required
                        @changed-valid="changedValid"
                    />
                    <div v-if="d.sls_count > 1" class="note warning">
                        売上計上日の自動更新対象外
                    </div>
                </div>

                <div class="item dep-div">
                    <OpDivSelector
                        label="入金済区分"
                        v-model="d.deposit_div"
                        :divlist="divs['dep']"
                        name="deposit_div"
                        required
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('so-p')"
                    />
                </div>
                <div class="item dep-date">
                    <OpDateTextField
                        label="入金日"
                        v-model.trim="d.deposit_date"
                        name="deposit_date"
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('so-p')"
                        :disabled="d.deposit_div == '9'"
                        :required="d.deposit_div == '1'"
                    />
                    <div class="note">
                        {{
                            descDepositTerm(op.salesorder_customer_deposit_term)
                        }}
                    </div>
                </div>
                <div class="item est-num">
                    <div class="title">見積書番号</div>
                    <div class="items">
                        <OpCheckbox
                            label="自動採番"
                            v-model="estimate_number_auto_flag"
                            name="estimate_number_auto_flag"
                        />
                        <OpNumberTextField
                            label="版"
                            v-model="d.estimate_version"
                            max="99"
                            min="0"
                            name="estimate_version"
                            :disabled="!estimate_number_auto_flag"
                            @changed-valid="changedValid"
                        />
                        <OpTextField
                            v-model.trim="d.estimate_number"
                            maxlength="20"
                            pattern="half"
                            name="estimate_number"
                            :disabled="estimate_number_auto_flag"
                            @changed-valid="changedValid"
                        />
                    </div>
                </div>
                <div class="item ord-num">
                    <OpTextField
                        label="注文番号"
                        v-model.trim="d.order_number"
                        maxlength="40"
                        name="order_number"
                        pattern="half"
                        @changed-valid="changedValid"
                    />
                </div>

                <div class="item inv-iss">
                    <OpDivSelector
                        label="請求書発行済区分"
                        v-model="d.invoice_issued_div"
                        :divlist="divs['invissued']"
                        name="invoice_issued_div"
                        required
                        @changed-valid="changedValid"
                        :readonly="!hasTicket('so-p')"
                    />
                </div>
                <div class="item inv-num">
                    <OpLabelField
                        label="請求書番号"
                        :text="
                            mode == 'update'
                                ? d.invoice_number
                                : '新規登録時に自動採番します'
                        "
                        :disabled="d.invoice_issued_div == '9'"
                    />
                </div>
                <div class="item tax-rate">
                    <OpNumberTextField
                        label="消費税"
                        v-model="d.tax_rate"
                        max="100"
                        min="0"
                        name="tax_rate"
                        suffix="%"
                        suffixwidth="1em"
                        :decimals="2"
                        required
                        @changed-valid="changedValid"
                    />
                </div>
                <div class="item so-note">
                    <OpTextField
                        label="受注備考"
                        v-model="d.salesorder_note"
                        name="salesorder_note"
                        maxlength="4000"
                        type="textarea"
                        inputStyle="height:200px;"
                        @changed-valid="changedValid"
                    />
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)">
            <table class="confirm">
                <!-- <tr><th>受注ID</th>
                <td>
                    <div v-if="mode == 'update'">
                        {{ d.salesorder_id }}
                    </div>
                    <div v-else>
                        登録時に採番されます
                    </div>
                </td></tr> -->
                <tr>
                    <th>受注件名</th>
                    <td>{{ d.salesorder_subject }}</td>
                </tr>
                <tr>
                    <th>受注ステータス</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.salesorder_status }}</span>
                            {{ divname("sosts", d.salesorder_status) }}
                        </span>
                    </td>
                </tr>

                <tr v-if="d.salesorder_status == 'Z'">
                    <th>取消理由区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.cancellation_div }}</span>
                            {{ divname("can", d.cancellation_div) }}
                        </span>
                    </td>
                </tr>
                <tr v-if="d.salesorder_status == 'Z'">
                    <th>取消理由備考</th>
                    <td>{{ d.cancellation_note }}</td>
                </tr>
                <tr>
                    <th>受注担当社員</th>
                    <td>
                        <span class="value">
                            <span class="id">{{
                                d.salesorder_employee.employee_id
                            }}</span>
                            {{ d.salesorder_employee.employee_name_long }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>受注金額</th>
                    <td>{{ d.salesorder_amount | amount }}</td>
                </tr>
                <tr>
                    <th>受注日</th>
                    <td>{{ d.salesorder_date | date }}</td>
                </tr>
                <tr>
                    <th>検収日</th>
                    <td>{{ d.acceptance_date | date }}</td>
                </tr>
                <tr>
                    <th>入金済区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.deposit_div }}</span>
                            {{ divname("dep", d.deposit_div) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>入金日</th>
                    <td>{{ d.deposit_date | date }}</td>
                </tr>

                <tr>
                    <th>見積書版</th>
                    <td>{{ d.estimate_version }}</td>
                </tr>
                <tr>
                    <th>見積書番号</th>
                    <td>{{ d.estimate_number }}</td>
                </tr>
                <tr>
                    <th>注文番号</th>
                    <td>{{ d.order_number }}</td>
                </tr>

                <tr>
                    <th>請求書発行済区分</th>
                    <td>
                        <span class="value">
                            <span class="code">{{ d.invoice_issued_div }}</span>
                            {{ divname("invissued", d.invoice_issued_div) }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>請求書番号</th>
                    <td>{{ d.invoice_number }}</td>
                </tr>
                <tr>
                    <th>消費税率</th>
                    <td>{{ d.tax_rate }} %</td>
                </tr>
                <tr>
                    <th>受注備考</th>
                    <td class="sonote">{{ d.salesorder_note }}</td>
                </tr>
            </table>

            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click="updateData" v-if="mode == 'update'">
                        登録
                    </button>
                    <button @click="addData" v-else>
                        追加
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message" v-if="mode == 'create'">
                受注 (ID : {{ d.salesorder_id }}) を登録しました。
            </div>
            <div class="message" v-if="mode == 'update'">
                受注 (ID : {{ d.salesorder_id }}) の情報を修正しました。
                <div v-if="msgcode == 2001">
                    <small
                        >売上の計上日および売上金額も更新しました。ご確認ください。</small
                    >
                </div>
                <div v-if="msgcode == 2002" class="warning">
                    <small
                        >⚠ 売上が複数件存在するため売上の計上日および売上金額は更新しておりません。</small
                    >
                </div>
                <div v-if="msgcode == 3001" class="warning">
                    <small
                        >「受注金額」と売上の「売上金額合計」が一致していません。<br />
                        「受注金額」または売上の「売上金額」修正してください。</small
                    >
                </div>
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <OpMessageModal
            v-if="showNotModified"
            title="メッセージ"
            @close="showNotModified = false"
        >
            変更箇所が見つからないため処理を中止しました。
        </OpMessageModal>
    </div>
</template>

<script>
// import OpDivMultiSelector from "@/components/OpDivMultiSelector.vue";
import EditorMixin from "@/views/EditorMixin.vue";
import OpSelectEmployee from "@/components/OpSelectEmployee.vue";
import OpMessageModal from "@/components/OpMessageModal.vue";

export default {
    name: "SalesorderEditor",
    mixins: [EditorMixin],
    components: { OpSelectEmployee, OpMessageModal },
    model: {
        prop: "model"
    },
    props: {
        model: Object,
        mode: String,
        opid: String,
        op: Object
    },
    data: function() {
        return {
            estimate_number_auto_flag: this.mode == "create",
            d: this.initModel(),
            cdialog: {
                okbutton: "OK",
                cancelbutton: "キャンセル",
                message: "",
                step: 0,
                execfunc: function() {}
            },
            inputed_estimate_number: this.model.estimate_number,
            msgcode: null,
            showNotModified: false
            // ,
            // sosts: this.slicechars(this.model.salesorder_status)
        };
    },
    watch: {
        estimate_number_auto_flag: function(val) {
            if (val) {
                this.inputed_estimate_number = this.d.estimate_number;
                let a = this.d.estimate_version;
                if (
                    this.mode == "update" &&
                    a !== null &&
                    a !== undefined &&
                    a !== ""
                ) {
                    this.d.estimate_number =
                        "E" + this.d.salesorder_id + "-" + a;
                } else {
                    this.d.estimate_number = "";
                }
            } else {
                this.d.estimate_number = this.inputed_estimate_number;
            }
        },
        "d.estimate_version": function(val) {
            if (this.estimate_number_auto_flag) {
                if (
                    this.mode == "update" &&
                    val !== null &&
                    val !== undefined &&
                    val !== ""
                ) {
                    this.d.estimate_number =
                        "E" + this.d.salesorder_id + "-" + val;
                } else {
                    this.d.estimate_number = "";
                }
            }
        },
        "d.acceptance_date": function(val) {
            if (this.d.deposit_div == "0") {
                this.d.deposit_date = this.calcDepositPaymentDate(
                    val,
                    this.op.salesorder_customer_deposit_cutoff_day,
                    this.op.salesorder_customer_deposit_term,
                    this.op.salesorder_customer_deposit_day,
                    this.op.salesorder_customer_deposit_later_date_flag
                );
            }
        }
    },
    computed: {
        isStatusZ: function() {
            let ret = this.d.salesorder_status == "Z";
            if (!ret) {
                this.changedValid("can", true);
            }
            return ret;
        }
    },
    methods: {
        initModel: function() {
            let ret = { ...this.model };
            ret.salesorder_employee = {
                employee_id: ret.salesorder_employee_id,
                employee_name_long: ret.salesorder_employee_name_long
            };
            if (!ret.opportunity_id) {
                ret.opportunity_id = this.op.opportunity_id;
                ret.opportunity_subject = this.op.opportunity_subject;
                ret.estimate_version = 1;
                ret.deposit_div = "0";
                ret.invoice_issued_div = "0";
                if (!ret.salesorder_employee_id) {
                    ret.salesorder_employee = this.getCurrentEmployee();
                }
            }
            if (ret.polist) {
                delete ret.polist;
            }
            if (ret.slslist) {
                delete ret.slslist;
            }
            if (!ret.tax_rate) {
                ret.tax_rate = this.getCurrentTaxRate();
            }
            return ret;
        },
        descDepositTerm: function() {
            let ret = "";
            let cday = this.op.salesorder_customer_deposit_cutoff_day;
            if (cday == 31) {
                ret = "月末締";
            } else {
                ret = "毎月" + cday + "日締";
            }
            ret += " / ";
            let val = this.op.salesorder_customer_deposit_term;
            if (val === 0 || val === "0") {
                ret += "当月";
            } else if (val == 1) {
                ret += "翌月";
            } else if (val == 2) {
                ret += "翌々月";
            } else {
                ret += val + "ヵ月後の";
            }
            let pday = this.op.salesorder_customer_deposit_day;
            if (pday == 31) {
                pday = "末";
            }
            ret += pday + "日入金";
            return ret;
        },
        upperValue: function(val) {
            let ret = val;
            if (val) {
                ret = val.toUpperCase();
            }
            return ret;
        },
        doConfirm: function() {
            this.msgcode = null;
            this.setStep(1);
        },
        close: function() {
            this.$emit("fin");
        },
        addData: function() {
            this.d.salesorder_employee_id = this.d.salesorder_employee.employee_id;
            this.postData(this.makeurl("so"), this.d, dt => {
                this.d = dt;
                this.$emit("soUpdated", dt);
                this.setStep(2);
            });
        },
        updateData: function() {
            this.d.salesorder_employee_id = this.d.salesorder_employee.employee_id;
            this.putData(
                this.makeurl("so", this.d.salesorder_id),
                this.d,
                (dt, response) => {
                    if (dt.salesorder_id) {
                        this.d = dt;
                        this.msgcode = response.headers["mop-message-code"];
                        this.$emit("soUpdated", dt);
                        this.setStep(2);
                    } else {
                        this.showNotModified = true;
                        this.setStep(0);
                    }
                }
            );
        },
        divname: function(divkey, code) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentlong;
            }
            return ret;
        }
        // ,
        // onChangeAcceptanceDate: function() {
        //     if (this.d.deposit_div == "0") {
        //         this.d.deposit_date = this.d.acceptance_date;
        //     }
        // }
    },
    created: async function() {
        await this.loaddivs("sosts", "can", "invissued", "dep");
        this.$refs.salesorderSubject.focus();
    }
};
</script>

<style scoped>
.so-edit-panel {
    position: relative;
    min-width: 600px;
    padding: 0;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}
/* --- header --- */
.so-edit-panel .header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--main-context-bg-color);
    filter: drop-shadow(var(--layerd-shadow));
    margin-bottom: 5px;

    display: grid;
    grid-template-areas:
        "opsbj   opsbj"
        "scust   opdept";
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    align-items: initial;
}
.so-edit-panel .header .opsbj {
    grid-area: opsbj;
}
.so-edit-panel .header .scust {
    grid-area: scust;
}
.so-edit-panel .header .opdept {
    grid-area: opdept;
}

/* --- form --- */
.so-edit-form {
    position: relative;
    display: grid;
    grid-template-columns: 200px 120px 100px 80px 1fr;
    grid-template-areas:
        "so-sbj     so-sbj so-sbj     so-sbj           so-sbj"
        "sosts      sosts sosts      sosts          sosts"
        "can-div    can-div can-note    can-note      can-note "
        "so-emp    so-emp so-amt      so-amt       so-date"
        "acpt-date  dep-div dep-div  dep-div     dep-date"
        "est-num    est-num est-num     est-num  est-num"
        "ord-num    ord-num so-note     so-note       so-note"
        "inv-iss    inv-iss    so-note   so-note       so-note"
        "inv-num    tax-rate    so-note   so-note    so-note";
    grid-row-gap: 10px;
    grid-column-gap: 30px;
    align-items: initial;
    z-index: 0;
    padding: 5px;
}

.so-edit-form .form-field {
    width: 100%;
}

.so-edit-form .so-sbj {
    grid-area: so-sbj;
}

.so-edit-form .sosts {
    grid-area: sosts;
}
.so-edit-form .can-div {
    grid-area: can-div;
}
.so-edit-form .can-note {
    grid-area: can-note;
}
.so-edit-form .est-ver {
    grid-area: est-ver;
}

.so-edit-form .est-num {
    grid-area: est-num;
    position: relative;
    padding-top: 10px;
}

.so-edit-form .est-num .items .form-field {
    width: auto !important;
    padding: 0 10px;
}
.so-edit-form .est-num .items {
    display: flex;
    flex-flow: row;
    align-items: top;
    justify-content: flex-start;
    margin-top: 0rem;
}
.so-edit-form .est-num .items .form-field:first-child {
    width: auto;
    padding-right: 10px;
    padding-bottom: 0.5rem;
    align-self: center;
}

.so-edit-form .ord-num {
    grid-area: ord-num;
}
.so-edit-form .so-emp {
    grid-area: so-emp;
}
.so-edit-form .so-amt {
    grid-area: so-amt;
}
.so-edit-form .so-date {
    grid-area: so-date;
}
.so-edit-form .acpt-date {
    grid-area: acpt-date;
}
.so-edit-form .dep-date {
    grid-area: dep-date;
}
.so-edit-form .inv-num {
    grid-area: inv-num;
}
.so-edit-form .inv-iss {
    grid-area: inv-iss;
}
.so-edit-form .dep-div {
    grid-area: dep-div;
}
.so-edit-form .so-note {
    grid-area: so-note;
}
.so-edit-form .tax-rate {
    grid-area: tax-rate;
}
.sonote {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    line-height: 1.5em;
}
/* --- complete --- */
.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}
.complete .message .warning {
    color: red;
}
.item .note {
    padding-top: 5px;
    font-size: 0.75rem;
}
.item .note:before {
    content: "※ ";
}
.item .warning {
    color: red;
}
</style>
<style>
.so-edit-form .statusValue {
    font-size: 1.25rem;
    width: 3rem;
    height: 2rem;
}
</style>

<template>
    <transition name="loading">
        <div class="opportunity-view" v-if="!loading">
            <div class="view-header">
                <div>
                    <!-- <div class="title">案件</div> -->
                    <div class="value">
                        <span class="id">{{ d.opportunity_id }}</span>
                        {{ d.opportunity_subject }}
                        <MopSubControlMenu id="oppoviewsubmenu" customlayout>
                            <div style="width:300px; text-align:center;">
                                <MopModifyButton @click.stop="editData" />
                                <MopCopyButton @click.stop="openPost" />
                                <MopLogButton @click.stop="openOpLog" />

                                <div style="text-align:left;">
                                    <div
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        作成 ：
                                        {{ d.create_timestamp | datetime }}
                                        {{ d.create_account_id }}
                                    </div>
                                    <div
                                        v-if="d.update_timestamp"
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        変更 ：
                                        {{ d.update_timestamp | datetime }}
                                        {{ d.update_account_id }}
                                    </div>
                                </div>

                                <div class="subcon-deletebutton">
                                    <MopDeleteButton
                                        @click.stop="deleteOp"
                                        :disabled="
                                            d.sales_exists_record == '1' ||
                                                d.costofsales_exists_record ==
                                                    '1'
                                        "
                                    />
                                </div>
                            </div>
                        </MopSubControlMenu>
                    </div>
                </div>
            </div>
            <div class="content top-content">
                <div class="item scust">
                    <!-- <div class="title">
                        受注先<span
                            class="code"
                            :title="d.salesorder_customer_id"
                            >{{ d.salesorder_customer_code }}</span
                        >
                    </div>
                    <div class="value">
                        {{ d.salesorder_customer_name_long }}
                    </div> -->

                    <div class="title">
                        受注先
                        <span class="code" :title="d.salesorder_customer_id">{{
                            d.salesorder_customer_code
                        }}</span>
                    </div>
                    <div class="value">
                        {{ d.salesorder_customer_name_short }}
                        <small>{{ d.salesorder_customer_name_long }}</small>
                    </div>
                </div>
                <div class="item ucust">
                    <!-- <div class="title">
                        ユーザ<span class="code" :title="d.user_customer_id">{{
                            d.user_customer_code
                        }}</span>
                    </div>
                    <div class="value">
                        {{ d.user_customer_name_long }}
                    </div> -->

                    <div class="title">
                        ユーザ
                        <span class="code" :title="d.user_customer_id">{{
                            d.user_customer_code
                        }}</span>
                    </div>
                    <div class="value">
                        {{ d.user_customer_name_short }}
                        <small>{{ d.user_customer_name_long }}</small>
                    </div>
                </div>
                <div class="item opdept">
                    <div class="title">
                        案件担当部署<span
                            class="code"
                            :title="d.opportunity_department_id"
                            >{{ d.opportunity_department_code }}</span
                        >
                    </div>
                    <div class="value">
                        {{ d.opportunity_department_name_short }}
                        <small>{{ d.opportunity_department_name_long }}</small>
                    </div>
                </div>
            </div>
            <div class="content other-content">
                <div class="item opdiv1">
                    <div class="title">商流</div>
                    <div class="value">
                        <!-- <span class="code">{{ d.opportunity_div1 }}</span> -->
                        {{ getDivname("op1", d.opportunity_div1) }}
                    </div>
                </div>

                <div class="item opdiv2">
                    <div class="title">経路</div>
                    <div class="value">
                        <!-- <span class="code">{{ d.opportunity_div2 }}</span> -->
                        {{ getDivname("op2", d.opportunity_div2) }}
                    </div>
                </div>

                <div class="item opdiv3">
                    <div class="title">科目</div>
                    <span class="value">
                        <!-- <span class="code">{{ d.opportunity_div3 }}</span> -->
                        {{ getDivname("op3", d.opportunity_div3) }}</span
                    >
                </div>

                <!-- <div class="item opdiv4">
                    <div class="title">案件区分4</div>
                    <span class="code">{{ d.opportunity_div4 }}</span>
                    <span class="value">{{
                        getDivname("op4", d.opportunity_div4)
                    }}</span>
                </div>

                <div class="item opdiv5">
                    <div class="title">案件区分5</div>
                    <span class="code">{{ d.opportunity_div5 }}</span>
                    <span class="value">{{
                        getDivname("op5", d.opportunity_div5)
                    }}</span>
                </div> -->

                <div class="item bznote">
                    <div class="title">商談備考</div>
                    <div class="value">{{ d.business_note }}</div>
                </div>

                <div class="item opnote">
                    <div class="title">案件備考</div>
                    <div class="value">
                        <div class="opnote-frame" ref="opnoteFrame">
                            <div v-for="(line, ix) in opnotes" :key="ix">
                                <div class="opnote-line">
                                    {{ line.message }}
                                    <span
                                        v-if="line.stamp"
                                        class="opnote-line-stamp"
                                    >
                                        {{ line.stamp }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="opnote-appender" style="">
                            <OpTextField
                                v-model="opnoteAppendValue"
                                type="textarea"
                                :maxlength="opappendMaxlength"
                                name="oppurtunity_note_appender"
                                @keydown.enter.ctrl.exact.stop.prevent="
                                    appendOpNote()
                                "
                                note="案件備考の最後に追記します(即時登録）。"
                            />
                        </div>
                        <button class="append-button" @click="appendOpNote()">
                            <IconBase iconName="追記" width="18" height="18"
                                ><IconPencil
                            /></IconBase>
                        </button>
                    </div>
                </div>
            </div>
            <div class="content sum-content">
                <div class="item op-status">
                    <div class="title">
                        全受注ステータス
                    </div>
                    <div class="value">
                        <span
                            v-for="(v, c) in viewStsDiv(
                                d.opportunity_salesorder_status
                            )"
                            :key="c"
                        >
                            <span class="statusValue"
                                >{{ c }}
                                <div class="statusName">
                                    {{ v }}
                                </div>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="item acpt-date">
                    <div class="title">
                        最新検収日
                    </div>
                    <div class="value">
                        {{ d.opportunity_acceptance_date | date }}
                    </div>
                </div>
                <div class="item so-amt amount">
                    <div class="title">
                        受注合計金額
                    </div>
                    <div class="value">
                        {{ d.opportunity_salesorder_amount | amount }}
                    </div>
                </div>
                <div class="item po-amt amount">
                    <div class="title">
                        発注合計金額
                    </div>
                    <div class="value">
                        {{ d.opportunity_purchaseorder_amount | amount }}
                    </div>
                </div>
                <div class="item sopo-amt amount">
                    <div class="title">
                        内部売上金額
                    </div>
                    <div class="value">
                        {{
                            (d.opportunity_salesorder_amount -
                                d.opportunity_purchaseorder_amount)
                                | amount
                        }}
                    </div>
                </div>
            </div>
            <div class="salesorder-list">
                <SalesorderList
                    :opid="opid"
                    :op="d"
                    :soid="soid"
                    :poid="poid"
                ></SalesorderList>
            </div>
        </div>
    </transition>
</template>

<script>
import SalesorderList from "@/views/opportunity/SalesorderList.vue";
import DateUtils from "@/DateUtils";
import MopLogButton from "@/components/buttons/MopLogButton.vue";
import MopModifyButton from "@/components/buttons/MopModifyButton.vue";
import MopCopyButton from "@/components/buttons/MopCopyButton.vue";
import MopDeleteButton from "@/components/buttons/MopDeleteButton.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import MopSubControlMenu from "@/components/MopSubControlMenu.vue";

const axios = require("axios");

export default {
    name: "opportunity-view",
    props: {
        opid: [Number, String],
        soid: [Number, String],
        poid: [Number, String],
        refreshNumber: Number
    },
    components: {
        SalesorderList,
        IconBase,
        IconPencil,
        MopLogButton,
        MopModifyButton,
        MopCopyButton,
        MopDeleteButton,
        MopSubControlMenu
    },
    data: function() {
        return {
            currentStep: 0,
            d: {},
            opnotes: [],
            opnoteAppendValue: "",
            loading: true
        };
    },
    watch: {
        refreshNumber: function() {
            this.load();
        }
    },
    computed: {
        opappendMaxlength: function() {
            let mx = 4000;
            if (this.d.opportunity_note) {
                mx -= this.d.opportunity_note.length;
            }
            return mx;
        }
    },
    methods: {
        openOpLog: function() {
            let state = this.$store.state.opportunityState.opportunityLog;
            state.model = { ...this.d };
            state.show = true;
        },
        openPost: function() {
            let state = this.$store.state.opportunityState.opportunityEditor;
            state.title = "案件登録";
            state.model = { ...this.d };
            state.updated = function() {};
            state.moveto = obj => {
                this.$emit("loadedOp", obj);
                this.d = obj;
                this.routePush({
                    name: "Opportunity",
                    params: { opid: this.d.opportunity_id }
                });
            };

            state.mode = "create";
            state.show = true;
        },
        editData: function() {
            let state = this.$store.state.opportunityState.opportunityEditor;
            state.title = "案件修正  (ID : " + this.d.opportunity_id + ")";
            state.model = this.d;
            state.updated = this.refresh;
            state.mode = "update";
            state.show = true;
        },
        editOpNote: function() {
            let state = this.$store.state.opportunityState.opportunityEditor;
            state.title = "案件修正  (ID : " + this.d.opportunity_id + ")";
            state.model = this.d;
            state.updated = this.refresh;
            state.mode = "opnote";
            state.show = true;
        },
        deleteOp: function() {
            let state = this.$store.state.confirmDialog;
            state.title = "案件削除前の確認";
            state.icon = "warn";
            state.confirm = [
                "案件（ID : " +
                    this.d.opportunity_id +
                    "）を削除してもよろしいですか？",
                "",
                {
                    text: "※ 該当案件に属する全てのデータを削除します。",
                    class: "warn"
                }
            ];
            state.complete = "削除しました。";
            state.okfunction = async func => {
                await this.deleteData(
                    this.makeurl("op", this.d.opportunity_id),
                    this.d,
                    func
                );
            };
            state.afterfunction = ok => {
                if (ok) {
                    this.routeReplace({ name: "op", params: {} });
                }
                this.$store.state.opportunityState.refreshNumber++;
            };
            state.show = true;
        },
        modalTitle: function() {
            return "案件登録  (ID : " + this.d.opportunity_id + ")";
        },
        refresh: function(d) {
            this.d = d;
            this.formatOpNote();
        },
        viewStsDiv: function(code) {
            let ret = {};
            if (code) {
                let len = code.length;
                const divdict = this.divs["sosts"];
                for (let i = 0; i < len; i++) {
                    let c = code.charAt(i);
                    const v = divdict[c];
                    if (v) {
                        ret[c] = v.contentshort;
                    } else {
                        ret[c] = c;
                    }
                }
            }
            return ret;
        },
        load: function() {
            this.loading = true;
            return axios
                .get(this.makeurl("op", this.opid))
                .then(response => {
                    this.d = this.convertFromResponseData(response.data);
                    this.formatOpNote();
                    this.$emit("loadedOp", this.d);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatOpNote: function() {
            let opn = this.d.opportunity_note;
            if (opn) {
                let lines = [];
                let opns = opn.split(/\n/);
                for (let s of opns) {
                    let msg = "";
                    let stm = "";
                    let ix = DateUtils.searchCommentTimestamp(s);
                    if (ix > 0) {
                        msg = s.slice(0, ix);
                        stm = s.slice(ix);
                    } else {
                        msg = s;
                    }
                    lines.push({ message: msg, stamp: stm });
                }
                this.opnotes = lines;
            } else {
                this.opnotes = null;
            }
        },
        appendOpNote: function() {
            let val = this.opnoteAppendValue.trimEnd();
            if (val) {
                val =
                    val +
                    "(" +
                    this.getAccountName() +
                    " " +
                    DateUtils.formatDatetime(new Date(), true) +
                    ")";
                this.opnoteAppendValue = "";
            }
            this.putData(
                this.makeurl("op", this.d.opportunity_id),
                {
                    opportunity_id: this.d.opportunity_id,
                    aopnote: val,
                    row_version: this.d.row_version
                },
                dt => {
                    this.d = dt;
                    this.formatOpNote();
                }
            );
        }
    },
    created: async function() {
        await this.loaddivs("op1", "op2", "op3", "op4", "op5", "sosts");
        await this.load();
    },
    updated: function() {
        let f = this.$refs.opnoteFrame;
        if (f) {
            f.scrollTop = f.scrollHeight - f.clientHeight;
        }
    }
};
</script>

<style scoped>
.opportunity-view {
    position: relative;
    min-height: 100vh;
    background-color: var(--base-color);
}

.view-header {
    margin: 0px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    padding: 0px;
    display: block;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 100vw;
    justify-content: center;
    background-color: var(--main-context-bg-color);
    height: 50px;
}

.view-header > div {
    margin-right: auto;
    margin-left: auto;
    width: 1040px;
    background-color: var(--glass-pane-bg-color);
}

.opportunity-view > .top-content {
    position: sticky;
    top: 0;
    width: 100%;
    height: 8rem;
    overflow: hidden;
    padding-top: 4rem;
    display: grid;
    background-color: var(--main-context-bg-color);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "scust        ucust     opdept  ";
    z-index: 4;
    /* border-bottom: 1px dashed var(--border-weak-color); */
    box-shadow: var(--panel-shadow);
}

.opportunity-view > .other-content {
    /* padding-top: 5rem; */
    background-color: var(--main-context-bg-color);
    display: grid;
    grid-template-columns: 200px 200px 4fr;
    grid-template-areas:
        "opdiv1    opdiv1        opnote"
        "opdiv2    opdiv2        opnote"
        "opdiv3    opdiv3        opnote"
        "bznote    bznote        opnote";
}
.opportunity-view > .sum-content {
    background-color: var(--main-context-bg-color);
    padding-top: 1rem;
    display: grid;
    grid-template-areas: "op-status op-status acpt-date so-amt  po-amt sopo-amt";
}
.opportunity-view .view-header {
    z-index: 5;
}
.opportunity-view .view-header > div {
    display: inline-block;
    margin-top: 5px;
    padding: 0px;
    font-size: 1.25rem;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
    border-radius: 10px;
    height: 3rem;
    position: relative;
}
.opportunity-view .view-header > div > div {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);
}
.opportunity-view .view-header .id {
    font-size: 0.75em;
}
.opportunity-view .view-header .id:after {
    content: " : ";
}

.opportunity-view .scust {
    grid-area: scust;
}
.opportunity-view .ucust {
    grid-area: ucust;
}
.opportunity-view .opdept {
    grid-area: opdept;
}
.opportunity-view .opdiv1 {
    grid-area: opdiv1;
}
.opportunity-view .opdiv2 {
    grid-area: opdiv2;
}
.opportunity-view .opdiv3 {
    grid-area: opdiv3;
}
.opportunity-view .opdiv4 {
    grid-area: opdiv4;
}
.opportunity-view .opdiv5 {
    grid-area: opdiv5;
}
.opportunity-view .bznote {
    grid-area: bznote;
}
.opportunity-view .bznote .title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.opportunity-view .bznote .value {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow-y: auto;
    height: 4em;
    /* border: 1px solid var(--border-weak-color); */
    line-height: 1.25em;
}
.opportunity-view .opnote {
    grid-area: opnote;
}
.opportunity-view .opnote > .title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.opportunity-view .opnote > .value {
    position: relative;
    padding: 0px;
}
.opportunity-view .opnote > .value .opnote-frame {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
    padding: 6px;
    line-height: 1.5em;
    border: 1px solid var(--border-color);
    background-color: var(--main-context-bg-color);
}
.opportunity-view .opnote .opnote-appender {
    position: absolute;
    bottom: 2px;
    left: 10px;
    width: calc(100% - 30px - 50px);
    padding-bottom: 0;
    background-color: var(--main-context-bg-color);
}

.opportunity-view .opnote .append-button {
    position: absolute;
    bottom: 30px;
    right: 30px;
}
/* .opnote .value {
    padding-bottom: 10rem;
} */
.opportunity-view .opnote .value > div > div {
    padding-top: 0.25rem;
    border-bottom: 1px solid var(--border-color);
}
.opportunity-view .opnote .value .opnote-frame > div:last-child {
    margin-bottom: 6rem;
}
.opportunity-view .opnote-line {
    clear: right;
    display: inline-block;
}
.opportunity-view .opnote-line-stamp {
    font-size: 0.75rem;
    float: right;
    color: var(--text-weak-color);
}

.opportunity-view .sysinfo {
    grid-area: sys;
    justify-self: right;
}
.opportunity-view .acpt-date {
    grid-area: acpt-date;
}
.opportunity-view .so-amt {
    grid-area: so-amt;
}
.opportunity-view .po-amt {
    grid-area: po-amt;
}
.opportunity-view .sopo-amt {
    grid-area: sopo-amt;
}

.sysinfo .item {
    display: inline-block;
    margin-left: 20px;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
}

/* ---- */
.salesorder-list {
    padding: 5px 0px;
    border-top: 1px solid var(--border-color);
}

/* --- */
.append-button {
    margin-top: 1ex;
    font-size: 1.25rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
}
.append-button:after {
    display: block;
    content: "追記";
    font-size: 0.5rem;
    margin: 0;
    padding: 0;
    margin-top: -1ex;
}

/* --- */

.loading-enter-active,
.loading-leave-active {
    transition: 0.3s;
}

.loading-enter,
.loading-leave-to {
    opacity: 0;
}

.title .code {
    padding-left: 8px;
    color: var(--text-weak-color);
}
.title .code::before {
    content: " : ";
}
.title .code::after {
    content: "";
}
</style>
<style>
.opportunity-view .opnote .opnote-appender .op-textfield-inner {
    height: 4rem;
}
.subcon-deletebutton {
    display: inline-block;
    padding-top: 10px;
}
</style>

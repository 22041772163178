<template>
    <button class="actionButton" @click="doClick">
        <div class="mark">
            <IconBase iconName="複製"><IconCopy /></IconBase>
        </div>
        <div class="txt">複製</div>
    </button>
</template>

<script>
import MopButtonMixin from "@/components/buttons/MopButtonMixin.vue";
import IconCopy from "@/components/icons/IconCopy.vue";

export default {
    name: "MopCopyButton",
    mixins: [MopButtonMixin],
    components: {
        IconCopy
    }
};
</script>

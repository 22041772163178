<template>
    <g>
        <g id="楕円形_1_18_">
            <g>
                <path
                    fill="#D94236"
                    d="M13.7480364,1.3080169c6.8594427,0,12.4400015,5.5805588,12.4400015,12.4400024
			c0,6.8594522-5.5805588,12.4400034-12.4400015,12.4400034S1.3080378,20.6074715,1.3080378,13.7480192
			C1.3080378,6.8885756,6.8885927,1.3080169,13.7480364,1.3080169 M13.7480364,0.3080168
			c-7.4227219,0-13.4399986,6.0172806-13.4399986,13.4400024c0,7.4227304,6.0172768,13.4400034,13.4399986,13.4400034
			s13.4400015-6.0172729,13.4400015-13.4400034C27.1880379,6.3252974,21.1707573,0.3080168,13.7480364,0.3080168
			L13.7480364,0.3080168z"
                />
            </g>
        </g>
        <g id="XMLID_37_">
            <path
                fill="#D94236"
                d="M8.3890276,12.8459806c-0.2402344-0.1000977-0.2802734-0.1000977-0.5-0.1201172
		c-0.2202148-0.0595703-0.4204102-0.1396484-0.4804688-0.159668c-0.4399414-0.1201172-0.7202148-0.2202148-0.8198242-0.2602539
		c-0.2001953-0.1000977-0.4003906-0.2001953-0.6201172-0.2797852c-0.1401367-0.0800781-0.300293-0.1401367-0.4604492-0.2202148
		c-0.0400391,0-0.1401367-0.1601563-0.2397461-0.3203125c-0.0400391-0.2597656-0.0600586-0.2998047-0.1401367-0.5200195
		c0.1201172-0.2998047,0.1401367-0.3398438,0.3798828-0.5800781c0.2001953-0.1601563,0.3803711-0.2397461,0.5-0.2397461
		c0.4003906,0,1.1806641,0.3598633,1.8608398,0.8598633c0.3598633,0.2802734,0.4199219,0.340332,0.699707,0.6000977
		c0.1000977,0.1401367,0.1201172,0.1801758,0.2001953,0.3803711c0.0400391,0.159668,0.0800781,0.3198242,0.0800781,0.4199219
		c0,0.2402344-0.1601563,0.4399414-0.3598633,0.4399414H8.3890276z M8.2488909,19.2873878
		c-0.0800781,0.3398438-0.1201172,0.5800781-0.1401367,0.6201172c-0.0400391,0.1201172-0.1201172,0.3203125-0.2597656,0.5800781
		c-0.0400391,0.0800781-0.0800781,0.1601563-0.1000977,0.1806641c-0.1000977,0.2392578-0.2202148,0.4599609-0.3203125,0.6796875
		c-0.199707,0.4199219-0.6401367,0.7197266-1.0400391,0.7197266c-0.2602539,0-0.4599609-0.0996094-0.800293-0.3798828
		c-0.0600586-0.2597656-0.1201172-0.4794922-0.1201172-0.5996094c0-0.1005859,0-0.2001953,0.0400391-0.3603516
		c0.0600586-0.0996094,0.1201172-0.2001953,0.1801758-0.2802734l0.1000977-0.1796875l0.5-0.8603516
		c0.300293-0.5800781,0.3398438-0.6796875,0.5600586-1.1601563c0.1000977-0.2402344,0.2202148-0.5,0.2202148-0.5
		c0.2197266-0.4804688,0.4199219-0.9599609,0.6201172-1.4404297c0,0,0.0600586-0.1000977,0.1401367-0.2402344
		c0.0600586-0.1601572,0.1196289-0.2998056,0.1396484-0.3598642c0.1201172-0.300293,0.2202148-0.5200195,0.2802734-0.6000977
		c0.0400391-0.0400391,0.1401367-0.1201172,0.2802734-0.2001953c0.2197266,0.1201172,0.2397461,0.1601563,0.3198242,0.4199219
		L8.828969,15.7668791c0.0600586,0.239747,0.1000977,0.4399424,0.1000977,0.54004c0,0.1601563,0,0.1601563-0.2202148,1.2597656
		c-0.0400391,0.2802734-0.1000977,0.4003906-0.2397461,0.5800781c-0.1201172,0.4208984-0.1401367,0.5009766-0.1401367,0.8408203
		L8.2488909,19.2873878z M6.6282854,5.7043791c0.2802734-0.3198242,0.6005859-0.5200195,0.7802734-0.5200195
		c0.2001953,0,0.5,0.1401367,0.9003906,0.4204102c0.3598633,0.2197266,0.4199219,0.2597656,0.7602539,0.5800781
		c0.2397461,0.159668,0.2797852,0.199707,0.3999023,0.3999023C9.6492815,6.6848478,9.669301,6.7048674,9.7894182,6.8650236
		c0.0200195,0.1196289,0.0200195,0.1396484,0.0795898,0.2197266c0,0.6000977-0.2597656,0.9003906-0.7797852,0.9003906
		c-0.5800781,0-1.7607422-0.5600586-2.5205078-1.1801758c0-0.2602539,0-0.3203125-0.0600586-0.6000977
		C6.528676,5.9846525,6.5486956,5.9446135,6.6282854,5.7043791z M8.9490862,21.6477394
		c0.0200195-0.0595703,0.0200195-0.0996094,0.0200195-0.1396484c0-0.0205078,0-0.0605469-0.0200195-0.1201172
		c0.0600586-0.3798828,0.0600586-0.5,0.0600586-0.7802734c0.1201172-0.1601563,0.1201172-0.1796875,0.199707-0.3603516
		c0.7202148-0.0195313,1.0405273-0.0996094,1.6206055-0.4599609c0.3999023-0.2802734,0.5200195-0.3798828,1-0.9404297
		c0.2402344-0.2998047,0.2802734-0.359375,0.4804688-0.7001953c0.2197266-0.7597656,0.2397461-0.8994141,0.2998047-1.8198242
		c0.1601563-0.0400391,0.1801758-0.0600586,0.3203125-0.1401367c0.2998047,0.0800781,0.3798828,0.2802734,0.3798828,1.0605469
		c0,0.4794922-0.0400391,0.7597656-0.2402344,1.3203125c-0.0795898,0.5-0.0996094,0.5800781-0.2998047,1.1396484
		c-0.1401367,0.4599609-0.1801758,0.5400391-0.5,1c-0.1000977,0.0605469-0.1201172,0.0605469-0.2202148,0.1005859
		c-0.2402344,0.3798828-0.2802734,0.4404297-0.5800781,0.7597656c-0.9204102,0.7207031-1.0400391,0.8007813-1.4404297,0.9609375
		c-0.5200195-0.0400391-0.6000977-0.0605469-0.9799805-0.2402344L8.9490862,21.6477394z M9.2689104,13.4460783
		c0.0400391-0.199707,0.0600586-0.2397461,0.1401367-0.3798828c0-0.3598633,0.0200195-0.3999023,0.340332-0.5800781
		c0.1196289,0.0600586,0.199707,0.0800781,0.2197266,0.0800781c0.6401367-0.0800781,0.6401367-0.0800781,0.7001953-0.0800781
		l0.3603516-0.0200195c0.0800781-0.0200195,0.3198242-0.1201172,0.7397461-0.2802734l0.6401367-0.0200195l0.7802734-0.199707
		c0,0,0.0400391,0.0200195,0.1000977,0.0400391c0.1601563-0.0600586,0.1801758-0.0600586,0.2802734-0.2001953
		c0.9204102-0.2202148,1.0405273-0.300293,1.0405273-0.5400391c0-0.1401367-0.1201172-0.4003906-0.3408203-0.7402344
		l-0.159668-0.5200195c0.0400391-0.1801758,0.0400391-0.2001953,0.1401367-0.3203125
		c0.5400391,0.2202148,0.6396484,0.300293,0.8603516,0.6401367c0.2197266,0.0400391,0.4199219,0.0600586,0.5195313,0.0600586
		c0.1005859,0,0.2402344-0.0200195,0.4404287-0.0400391c0.0595703,0.0600586,0.0800781,0.0800781,0.1396484,0.0800781h0.0605469
		c0.5996094-0.2001953,0.7197266-0.2402344,1.1601563-0.3598633c0.0996094-0.1801758,0.1601563-0.2402344,0.2998047-0.2402344
		c0.0605469,0,0.1201172,0,0.2001953,0.0200195l0.0400391,0.4799805c-0.1201172,0.0400391-0.1601563,0.0400391-0.2402344,0.0400391
		c-0.0800781,0.2602539-0.0996094,0.3803711-0.0996094,0.5200195c0,0.1601563,0.0195313,0.300293,0.0800781,0.7202148
		c0.4199219,0.4599609,0.5400391,0.5200195,1.3398438,0.5600586c0.1201172-0.0200195,0.2001953-0.0200195,0.2597656-0.0200195
		c0.1005859,0,0.2607422,0.0200195,0.4404297,0.0600586c0.0996094-0.0200195,0.1601563-0.0400391,0.2001953-0.0400391
		s0.3203125,0.0600586,0.7197266,0.1601563c0.1201172,0.0400391,0.3203125,0.0600586,0.5800781,0.0600586
		c0.1601563-0.1401367,0.2802734-0.2202148,0.3603516-0.2202148c0.0400391,0,0.1201172,0.0200195,0.2001953,0.0600586
		c0.1396484,0.1801758,0.1601563,0.2001953,0.3203125,0.3603516c0.1396484,0.2197266,0.1601563,0.2597656,0.2392578,0.4399414
		c0.1806641,0.2602539,0.2001953,0.3198242,0.3203125,0.6801758c-0.3798828,0.3398438-0.5996094,0.4399414-0.9404297,0.4399414
		c-0.0595703,0-0.3193359-0.0200195-0.7392578-0.0600586c-0.1601563-0.0600586-0.3007813-0.0996094-0.4003906-0.0996094
		c-0.1005859,0-0.1005859,0-0.3603516,0.3999023c-0.2802734,0.3999023-0.2802734,0.3999023-0.4804688,0.5800781
		l-0.2792969,0.6000977l0.0800781,0.4599619c-0.0205078,0.0400391-0.0205078,0.0800781-0.0205078,0.1000977l0.1806641,0.9399414
		c0,0.0205078-0.0400391,0.0800781-0.0800781,0.1601563c0.0195313,0.1601563,0.0400391,0.2802734,0.0400391,0.3798828
		c0,0.0205078,0,0.1005859-0.0205078,0.1806641c0.0205078,0.1396484,0.0400391,0.1601563,0.0400391,0.2802734
		c0.0205078,0.2792969,0.0400391,0.359375,0.1396484,0.5c0.0605469,0.2001953,0.0800781,0.2792969,0.1005859,0.5996094
		c0.0195313,0.1601563,0.0195313,0.2607422,0.0195313,0.3398438c0,0.0605469,0,0.1601563-0.0195313,0.2802734
		c0.1796875,0.1806641,0.2001953,0.2001953,0.2597656,0.4599609v0.1005859l0.0605469,1.0595703c0,0,0,0.0605469-0.0205078,0.140625
		c-0.1201172,0.2402344-0.1396484,0.2998047-0.2197266,0.6201172c-0.2998047,0.2802734-0.6005859,0.4404297-0.7607422,0.4404297
		c-0.1201172,0-0.3193359-0.0800781-0.5800781-0.2207031c-0.2197266-1.2802734-0.2597656-1.6601563-0.2597656-2.1601563
		c0-0.4404297,0-0.4404297,0.2001953-2.2207031l0.0595703-0.8203125c0.0400391-0.2597656,0.140625-0.5400391,0.3007813-0.8598633
		c-0.0400391-0.0600586-0.0605469-0.1000977-0.0605469-0.1000977l0.140625-0.6801767
		c0-0.0200195-0.0400391-0.0800781-0.0800781-0.1000977c-0.140625-0.0400391-0.1601563-0.0600586-0.1806641-0.199707
		c-0.0996094-0.3803711-0.1201172-0.5205078-0.1201172-0.8803711l0.0205078-0.6601563
		c0-0.0400391-0.0205078-0.1201172-0.0400391-0.2402344c-0.3203125-0.1000977-0.7607422-0.2397461-1.3603516-0.4599609
		c-0.2197266-0.1000977-0.2597656-0.1201172-0.4599609-0.2802734c-0.140625-0.199707-0.2802734-0.3198242-0.3603516-0.3198242
		l-0.4199219-0.0600586c-0.0205078,0.0200195-0.1601553-0.0200195-0.2802725-0.0600586
		c-0.0996094,0.0200195-0.2001953,0.0200195-0.2197266,0.0200195c-0.3007813,0.0200195-0.4804688,0.0400391-0.5,0.0400391
		c-0.1601563,0.1401367-0.3203125,0.2602539-0.4599609,0.3798828c-0.0400391,0.0400391-0.1201172,0.0800781-0.2207031,0.1201172
		c-0.4399414,0.0600586-0.5400391,0.1000977-0.7998047,0.2602539c-0.1401367,0.1201172-0.1601563,0.1601563-0.2202148,0.3198242
		c-0.0800781,0.0800781-0.0800781,0.0800781-0.2001953,0.1401367c-0.0600586,0.1000977-0.1000977,0.1801758-0.1000977,0.2402344
		s0.0600586,0.1601563,0.1601563,0.2998047c0.1000977,0.1601563,0.1401367,0.2602539,0.1401367,0.340332
		c0,0.0400391-0.0400391,0.1000977-0.1000977,0.1796875c0.0200195,0.1201172,0.0200195,0.1801758,0.0200195,0.2402344
		c0,0.1000977-0.0400391,0.2602539-0.0800781,0.4599609c-0.0800781,0.0200195-0.1201172,0.0200195-0.1601563,0.0200195
		c-0.0996094,0-0.2597656-0.0600586-0.4199219-0.1796875c-0.2797852-0.4804688-0.3398438-0.5405273-0.6000977-0.9404297
		c-0.2202148-0.1601563-0.3999023-0.2397461-0.5200195-0.2397461c-0.0600586,0-0.2402344,0.0595703-0.4799805,0.159668
		c-0.1801758,0.0800781-0.340332,0.1201172-0.5004883,0.1201172c-0.0996094,0-0.2998047-0.0400391-0.5-0.1000977
		c-0.4199219-0.0400391-0.4799805-0.0600586-0.7402344-0.2597656L9.2689104,13.4460783z M15.2903948,7.9851408
		c-0.4003906,0.0200195-0.4599609,0.0400391-0.8203125,0.1201172c-0.0195313,0.1000977-0.0595703,0.1796875-0.1796875,0.3598633
		c-0.2001953,0.4003906-0.2202148,0.4799805-0.2602539,0.9604492c-0.1601563,0.0600586-0.2602539,0.0795898-0.3598633,0.0795898
		c-0.0200195,0-0.0800781-0.0195313-0.1601563-0.0395508c-0.1201172-0.2001953-0.1401367-0.2402344-0.3203125-0.3203125
		c-0.2998047-0.3198242-0.3598633-0.3798828-0.5400391-0.6000977c-0.2597656,0-0.4599609,0.0200195-0.5400391,0.0600586
		c-0.2001953,0.1000977-0.4199219,0.2001953-0.6401367,0.2998047c-0.6201172-0.0395508-1.0200195-0.0795898-1.1801758-0.0996094
		c-0.2602539-0.0800781-0.300293-0.1000977-0.4599609-0.2202148c0.0595703-0.5,0.0996094-0.5800781,0.3798828-0.9003906
		c0.4199219-0.3598633,0.6601563-0.4599609,1.1000977-0.4599609c0.2402344-0.0400391,0.4204102-0.0600586,0.5-0.0600586
		l1.0805664-0.1401367c0.2197266-0.0200195,0.4599609-0.0400391,0.6801758-0.0400391
		c0.2397461,0,0.4199219,0.0400391,0.7797852,0.1401367c0.140625-0.199707,0.1806641-0.2597656,0.4199219-0.5200195
		c0.1005859-0.1401367,0.1806641-0.2402344,0.1806641-0.2602539c0.1396484-0.2197266,0.2597656-0.4199219,0.3798828-0.6401367
		c0.0195313-0.0400391,0.0595703-0.0996094,0.1396484-0.2197266l0.2607422-0.6801758
		c0.2197266,0.0400391,0.2392578,0.0600586,0.359374,0.2001953c0.1201172,0.1196289,0.2001953,0.2197266,0.2207031,0.2797852
		l0.1601563,0.5600586c0.0400391,0.0600586,0.1191406,0.2001953,0.2392578,0.3603516
		c0.1201172,0.2597656,0.1601563,0.3198242,0.4003906,0.6000977c0.4199219-0.0200195,0.5605469-0.0400391,0.9804688-0.1000977
		c0.1796875-0.0200195,0.2998047-0.0400391,0.3603516-0.0400391c0.0195313,0,0.4599609,0.0600586,1,0.1401367
		c0.1396484,0.0400391,0.4794922,0.0600586,0.8798828,0.0600586c0.5605469-0.0400391,1.6005859,0.3398438,2.140625,0.7797852
		c0.2197266,0.2402344,0.2402344,0.2802734,0.2597656,0.5605469c-0.2597656,0.1196289-0.2998047,0.1196289-0.5400391,0.1396484
		c-0.3603516,0.1601563-0.5595703,0.2202148-0.8603516,0.2202148c-0.2597656,0-0.4599609-0.0600586-0.8398438-0.2202148
		c-0.3203125-0.0600586-0.4003906-0.0800781-0.8398438-0.1000977c-0.1806641,0.1401367-0.2207031,0.2202148-0.2802734,0.5805664
		C18.8909798,9.0451994,18.8314095,9.105258,18.4905891,9.44559c-0.0595703,0.0400391-0.1191406,0.0595703-0.1796875,0.0595703
		c-0.1396484,0-0.2402344-0.1796875-0.2402344-0.3999023c0-0.1401367,0.0205078-0.2998047,0.0800781-0.5800781
		c-0.0996094-0.0800781-0.1201172-0.1000977-0.2597656-0.2202148c-0.7197266-0.3999023-1.0800781-0.5-1.640625-0.5
		c-0.1396484,0-0.2802725,0-0.5595694,0.0200195l-0.1806641,0.0800781L15.2903948,7.9851408z M16.5306282,18.7678566
		c-0.0595703,0-0.2001953,0.0791016-0.4199219,0.2197266c-0.1005859,0.0195313-0.1601553,0.0195313-0.2402334,0.0195313
		c-0.0595703,0-0.2001953-0.0195313-0.3603516-0.0800781c-0.2197266-0.0195313-0.4199219-0.0400391-0.5-0.0595703
		c-0.3398438-0.0400391-0.5-0.0800781-0.6396484-0.2001953c-0.140625,0.0205078-0.2802734,0.0400391-0.3603516,0.0400391
		c-0.1000977,0-0.1601563-0.0195313-0.300293-0.0800781c-0.0200195-0.0800781-0.0200195-0.1396484-0.0200195-0.2001953
		c0-0.1201172,0.0600586-0.2802734,0.1801758-0.5c0.2602539-0.0996094,0.3198242-0.1201172,0.6206055-0.2802734
		c0.1201172,0.0205078,0.2197266,0.0400391,0.2998047,0.0400391c0.1396484,0,0.2802734-0.0595703,0.4794922-0.2197266
		c0.2607422-0.0800781,0.4609375-0.1396484,0.6005859-0.1396484c0.2802725,0,0.2802725,0,1.3007803,0.5
		c0.5195313,0.2597656,0.7197266,0.4394531,0.7197266,0.6601563c0,0.0595703,0,0.1201172-0.0205078,0.2001953
		c-0.1191406,0.0800781-0.2792969,0.1796875-0.3193359,0.1796875L16.5306282,18.7678566z M14.4700823,15.1067228
		c0.3603516-0.1401367,0.4199219-0.1801758,0.640625-0.4003906c0.3193359-0.0600586,0.6201172-0.0996094,0.7998047-0.0996094
		c0.4003897,0,0.9404287,0.1396484,1.2802725,0.3198242s0.4599609,0.300293,0.5205078,0.6401367
		c-0.140625,0.2602539-0.1806641,0.2802734-0.5205078,0.3798828c-0.2001953,0.0200195-0.2197266,0.0400391-0.3798828,0.1401377
		c-0.2402344,0.0800781-0.4404297,0.1201172-0.5800781,0.1201172c-0.0800781,0-0.2207031-0.0400391-0.4003897-0.1000977
		c-0.1201172,0.0400391-0.2001953,0.0600586-0.2402344,0.0600586c-0.0800781,0-0.2597656-0.0200195-0.5400391-0.0800781
		c-0.1796875-0.0200195-0.4003906-0.0400391-0.6601563-0.0600586c-0.3598633-0.04004-0.6000977-0.1596689-0.6000977-0.3198252
		c0-0.1000977,0.0800781-0.2202148,0.2602539-0.4003906C14.2298479,15.2068205,14.2503557,15.186801,14.4700823,15.1067228z"
            />
        </g>
    </g>
</template>
<script>
export default {
    name: "IconCaution",
    mounted: function() {
        this.$emit("viewbox", "0 0 27.4960632 27.4960632");
    }
};
</script>
<style>
.st0 {
    fill: #4b4b4b;
}
</style>

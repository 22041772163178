<template>
    <div class="cos-panel">
        <div v-if="isStep(0)">
            <table class="cosedittable">
                <tbody v-for="d in datalist" :key="d.x_costofsales_id">
                    <tr>
                        <td
                            colspan="7"
                            :class="['item', { delete: d.delete_flag }]"
                        >
                            <span class="title">原価ID</span> :
                            <span class="value">
                                <span v-if="d.costofsales_id">{{
                                    d.costofsales_id
                                }}</span>
                                <span v-else>--------</span>
                            </span>
                            <span
                                v-if="d.record_flag"
                                style="position :relative; display:inline-block; overflow:visible; margin-left:10px; width:24px; height:1em;"
                            >
                                <ZumiStamp :width="24" />
                            </span>
                            <span class="flaglabel" v-if="d.record_flag"
                                >計上済</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                        <td
                            class="item cos-dept"
                            colspan="1"
                            style="overflow:visible;"
                        >
                            <OpSelectDepartment
                                label="原価部署"
                                v-model="d.costofsales_department"
                                required
                                :name="'costofsales_department' + d.xix"
                                @changed-valid="changedValid"
                                ref="costofsalesDepartment"
                                :readonly="d.record_flag"
                                :disabled="d.delete_flag"
                                type="short"
                                maxwidth="16rem"
                                div="1"
                                :referenceDate="d.record_date"
                            />
                        </td>
                        <td
                            class="item rec-date"
                            style="overflow:visible; width:12rem;"
                        >
                            <OpDateTextField
                                label="計上日"
                                v-model.trim="d.record_date"
                                :name="'record_date' + d.xix"
                                required
                                @changed-valid="changedValid"
                                :readonly="d.record_flag"
                                :disabled="d.delete_flag"
                            />
                        </td>
                        <td class="amount">
                            <OpNumberTextField
                                label="原価金額"
                                v-model.trim="d.costofsales_amount"
                                max="999999999999"
                                min="0"
                                :name="'costofsales_amount' + d.xix"
                                required
                                @changed-valid="changedValid"
                                @liveinput="d.liveInputAmount"
                                :readonly="d.record_flag"
                                :disabled="d.delete_flag"
                            />
                        </td>
                        <!-- <td class="item tax-rate">
                            <OpNumberTextField
                                label="消費税"
                                v-model="d.tax_rate"
                                max="100"
                                min="0"
                                :name="'tax_rate' + d.xix"
                                suffix="%"
                                suffixwidth="1em"
                                :decimals="2"
                                required
                                @changed-valid="changedValid"
                                :readonly="d.record_flag"
                                :disabled="d.delete_flag"
                            />
                        </td> -->
                        <td class="item cos-note">
                            <OpTextField
                                label="備考"
                                type="textarea"
                                v-model="d.costofsales_note"
                                :name="'costofsales_note' + d.xix"
                                maxlength="240"
                                maxwidth="15rem"
                                @changed-valid="changedValid"
                                :readonly="d.record_flag"
                                :disabled="d.delete_flag"
                            />
                        </td>
                        <td style="overflow:visible; vertical-align:middle;">
                            <div v-if="payload_data.length > 1">
                                <OpCheckbox
                                    v-if="!d.record_flag && d.costofsales_id"
                                    label="削除"
                                    v-model="d.delete_flag"
                                    name="delete_flag"
                                    styleType="bottomlabel"
                                    @input="changedDeleteFlag(d)"
                                />
                                <button
                                    v-if="!d.costofsales_id"
                                    @click="deleteRow(d)"
                                >
                                    削除
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td rowspan="2" colspan="2" class="addbuttons">
                            <button class="addButton" @click="appendRow">
                                +
                            </button>
                            <div class="copy-flag">
                                <OpCheckbox
                                    label="最終行を複製"
                                    v-model="copy_flag"
                                    name="copy_flag"
                                />
                            </div>
                        </td>
                        <td colspan="1" class="amount">
                            原価金額合計① =
                        </td>
                        <td class="amount">
                            <span class="total">{{
                                amounttotal | amount
                            }}</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="1" class="amount">
                            ① - 発注金額 =
                        </td>
                        <td class="amount">
                            <span
                                :class="[
                                    'total',
                                    { 'amount-notmatch': amountdiff != 0 }
                                ]"
                            >
                                <span v-if="amountdiff > 0"> + </span>
                                {{ amountdiff | amount }}</span
                            >
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click="doConfirm" v-bind:disabled="hasErrors">
                        確認
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)">
            <table>
                <thead>
                    <tr>
                        <th>原価ID</th>
                        <th>原価部署</th>
                        <th>計上日</th>
                        <th>計上済</th>
                        <th>原価金額</th>
                        <!-- <th>消費税率</th> -->
                        <th>備考</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="d in confirmDatalist" :key="d.x_costofsales_id">
                        <td>
                            <span v-if="d.costofsales_id">{{
                                d.costofsales_id
                            }}</span>
                            <span v-else>--------</span>
                        </td>
                        <td>
                            {{ d.costofsales_department.department_name_short }}
                        </td>
                        <td>{{ d.record_date | date }}</td>
                        <td>{{ d.record_flag | flag }}</td>
                        <td class="amount">
                            {{ d.costofsales_amount | amount }}
                        </td>
                        <!-- <td class="amount">{{ d.tax_rate }} %</td> -->
                        <td class="note">{{ d.costofsales_note }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4" class="amount">
                            原価金額合計① =
                        </td>
                        <td class="amount">
                            <span class="total">{{
                                amounttotal | amount
                            }}</span>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="4" class="amount">
                            ① - 発注金額 =
                        </td>
                        <td class="amount">
                            <span
                                :class="[
                                    'total',
                                    { 'amount-notmatch': amountdiff != 0 }
                                ]"
                            >
                                <span v-if="amountdiff > 0"> + </span>
                                {{ amountdiff | amount }}</span
                            >
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <div class="control-box">
                <div>
                    <button @click="setStep(0)">
                        戻る
                    </button>
                </div>
                <div>
                    <button @click="updateData">
                        登録
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(2)" class="complete">
            <div class="message">
                原価情報 を登録しました。
            </div>

            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EditorMixin from "@/views/EditorMixin.vue";
import ZumiStamp from "@/components/ZumiStamp.vue";

export default {
    name: "CostofsalesListEditor",
    mixins: [EditorMixin],
    props: {
        po: Object,
        op: Object
    },
    components: {
        ZumiStamp
    },
    data: () => ({
        showEdit: false,
        showCreate: false,
        selectedData: null,
        amountMap: new Map(),
        amounttotal: 0,
        amountdiff: 0,
        rowaddcount: 0,
        copy_flag: true,
        payload_data: []
    }),
    computed: {
        showSubCtrlMenu: function() {
            let ret = null;
            if (this.selectedData) {
                let ele = this.$store.state.clickedTarget;
                if (ele) {
                    let tg = ele.closest("div.sub-control");
                    if (
                        tg &&
                        tg.id ==
                            "cossubcontrol" + this.selectedData.costofsales_id
                    ) {
                        ret = this.selectedData;
                    }
                }
            }
            return ret;
        },
        datalist: function() {
            for (let d of this.payload_data) {
                if (!d.costofsales_department) {
                    d.costofsales_department = {
                        department_id: d.costofsales_department_id,
                        department_code: d.costofsales_department_code,
                        department_name_long:
                            d.costofsales_department_name_long,
                        department_name_short:
                            d.costofsales_department_name_short
                    };
                }
                d.liveInputAmount = val => {
                    this.liveInputAmount(d, val);
                };
                if (d.costofsales_id) {
                    d.x_costofsales_id = d.costofsales_id;
                }
                if (!d.delete_flag) {
                    this.amountMap.set(
                        d.x_costofsales_id,
                        d.costofsales_amount
                    );
                }
            }
            return this.payload_data;
        },
        confirmDatalist: function() {
            return this.payload_data.filter(item => {
                return !item.delete_flag;
            });
        }
    },
    methods: {
        appendRow: function() {
            let mx = this.payload_data.length;
            let r = this.payload_data[mx - 1];
            let nr = {
                x_costofsales_id: this.rowaddcount++,
                purchaseorder_id: this.po.purchaseorder_id
            };
            if (this.copy_flag) {
                nr.costofsales_department = { ...r.costofsales_department };
                nr.record_date = r.record_date;
                nr.costofsales_amount = r.costofsales_amount;
                // nr.tax_rate = r.tax_rate;
                nr.costofsales_note = r.costofsales_note;
            } else {
                nr.costofsales_amount = 0;
                // nr.tax_rate = this.getCurrentTaxRate();
            }
            this.payload_data.push(nr);
            this.amountMap.set(nr.x_costofsales_id, nr.costofsales_amount);
            this.calcAmountTotal();
        },
        openPost: function() {
            let state = this.$store.state.opportunityState.costofsalesEditor;
            state.title = "原価登録";
            state.model = {};
            state.po = this.po;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "create";
            state.show = true;
        },
        showEditor: function(d) {
            let state = this.$store.state.opportunityState.costofsalesEditor;
            state.title = "売上修正 (ID : " + d.costofsales_id + ")";
            state.model = d;
            state.po = this.po;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "update";
            state.show = true;
        },
        showDeleteConfirm: function(d) {
            let state = this.$store.state.opportunityState.confirmDialog;
            state.title = "売上削除 (ID : " + d.costofsales_id + ")";
            state.confirm =
                "売上 (ID : " +
                d.costofsales_id +
                ") を削除してもよろしいですか？";
            state.complete = "削除しました。";
            state.okfunction = async func => {
                await this.deleteData(
                    this.makeurl("cos", d.costofsales_id),
                    d,
                    func
                );
                this.refresh(null);
            };
            state.show = true;
        },
        refresh: function() {
            this.$emit("beginLoad");
            this.load(() => {
                this.$emit("updated", this.payload_data);
                this.$emit("endLoad");
            });
        },
        load: function(func) {
            return this.readDataToArray(
                this.makeurl("cos", { po: this.po.purchaseorder_id }),
                list => {
                    this.payload_data = list;
                    if (func instanceof Function) {
                        func();
                    }
                }
            );
        },
        doOk: function() {
            this.close();
        },
        updateData: function() {
            let list = Array.from(this.payload_data);
            list = list.filter(d => {
                d.costofsales_department_id =
                    d.costofsales_department.department_id;
                return !d.record_flag;
            });

            this.putData(
                this.makeurl("cos", { po: this.po.purchaseorder_id }),
                list,
                dt => {
                    this.po.coslist = dt;
                    this.setStep(2);
                }
            );
        },
        doConfirm: function() {
            this.setStep(1);
        },
        liveInputAmount: function(d, val) {
            this.amountMap.set(d.x_costofsales_id, val);
            this.calcAmountTotal();
        },
        calcAmountTotal: function() {
            let ret = 0;
            for (let v of this.amountMap.values()) {
                if (typeof v == "string" && /^-?[0-9]+$/.test(v)) {
                    ret += Number.parseInt(v);
                } else if (typeof v == "number") {
                    ret += v;
                } else {
                    ret = NaN;
                    break;
                }
            }
            this.amounttotal = ret;

            this.amountdiff = this.amounttotal - this.po.purchaseorder_amount;
            this.changedValid("amountdiff", this.amountdiff == 0);
        },
        deleteRow: function(d) {
            this.payload_data = this.payload_data.filter(item => {
                let ret = item != d;
                if (!ret) {
                    this.amountMap.delete(d.x_costofsales_id);
                    this.changedValid("costofsales_department" + d.xix, true);
                    this.changedValid("record_date" + d.xix, true);
                    this.changedValid("costofsales_amount" + d.xix, true);
                    // this.changedValid("tax_rate" + d.xix, true);
                    this.changedValid("costofsales_note" + d.xix, true);
                }
                return ret;
            });
            this.calcAmountTotal();
        },
        changedDeleteFlag(d) {
            if (d.delete_flag) {
                this.amountMap.delete(d.x_costofsales_id);
            } else {
                this.amountMap.set(d.x_costofsales_id, d.costofsales_amount);
            }
            this.calcAmountTotal();
        }
    },
    updated: function() {
        this.$emit("endLoad");
    },
    created: async function() {
        this.$emit("beginLoad");
        if (!this.po.coslist) {
            await this.load();
        } else {
            this.payload_data = [];
            for (let d of this.po.coslist) {
                this.payload_data.push({ ...d });
            }
        }
        this.$emit("endLoad");
    },
    mounted: function() {
        this.calcAmountTotal();
    }
};
</script>

<style scoped>
.cos-panel {
    position: relative;
}
.cos-panel > .panel-header {
    width: 60px;
    text-align: center;
}
.cos-panel table {
    grid-area: table;
}
.cos-panel > .amountdiff-msg {
    grid-area: msg;
}
.cos-panel table.cosedittable > tbody td {
    vertical-align: top;
    border-style: none;
}
.cos-panel table.cosedittable > tbody > tr:last-child > td {
    border-bottom: 1px solid var(--border-color);
}

.cos-panel table > tfoot td {
    border-style: none;
}

.cos-panel table > tfoot > tr:first-child > td {
    border-top: 6px double var(--border-color);
}

.amount-notmatch {
    color: var(--error-text-color);
}
.total {
    display: inline-block;
}
.cosedittable .total {
    padding-right: 2rem;
}

.cos-panel h3 {
    font-weight: normal;
    font-size: 1.2em;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
}

td.note {
    width: 25%;
    white-space: pre-wrap;
}

/* --- */
.sub-control .sub-control-menu {
    width: 140px;
}
.sub-control .sysinfo {
    font-size: 0.75em;
    text-align: left;
}

.control-box {
    display: grid;
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
    border-top: 1px solid var(--border-color);
    margin-top: 10px;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}

.item .title {
    font-size: 0.75rem;
}
.item .flaglabel {
    font-size: 0.75rem;
}
.item.delete {
    text-decoration: line-through;
}

.addbuttons {
    vertical-align: top;
}
.copy-flag {
    padding: 0 10px 0px 10px;
    display: inline-block;
}
</style>
<style>
.cos-panel td.cos-note textarea.op-textfield-inner {
    height: 80px;
}
</style>

<template>
    <div class="salesorder-panel">
        <div>
            <div class="panel-header">
                <h2>
                    受注
                </h2>
                <MopAddButton @click="createData" />
                <MopLogButton @click="logSo" />
            </div>
        </div>
        <div class="op-so-list" v-if="payload_data && payload_data.length > 0">
            <div
                v-for="d in payload_data"
                :key="d.salesorder_id"
                :class="['op-so-row', { 'opened-so': isOpened(d) }]"
                :id="'salesorder' + d.salesorder_id"
            >
                <div class="op-so-row-top content">
                    <div class="item" @click="toggleSelectData(d)">
                        <span v-if="isOpened(d)">▼</span>
                        <span v-else>▶</span>
                    </div>
                    <div class="item">
                        <!-- <div class="title">受注</div> -->
                        <div class="value" style="white-space:normal;">
                            <div class="soid">受注 : {{ d.salesorder_id }}</div>
                            {{ d.salesorder_subject }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">
                            検収年月
                        </div>
                        <div class="value">
                            {{ d.acceptance_date | ymjp }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">
                            ステータス
                        </div>
                        <div class="value">
                            <div class="statusValue">
                                {{ d.salesorder_status }}
                                <div class="statusName">
                                    {{ divName("sosts", d.salesorder_status) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="item amount">
                        <div class="title">
                            受注金額
                        </div>
                        <div class="value">
                            {{ d.salesorder_amount | amount }}
                        </div>
                    </div> -->
                    <div class="item">
                        <div class="title">
                            請求書
                        </div>
                        <div class="divValue">
                            {{
                                divName("invissued", d.invoice_issued_div, true)
                            }}
                            <div class="divName">
                                {{
                                    divName(
                                        "invissued",
                                        d.invoice_issued_div,
                                        false
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">
                            入金
                        </div>
                        <div class="divValue">
                            {{ divName("dep", d.deposit_div, true) }}
                            <div class="divName">
                                {{ divName("dep", d.deposit_div, false) }}
                            </div>
                        </div>
                    </div>
                    <div class="item amount">
                        <div
                            style="border-bottom:1px solid var(--border-weak-color);"
                        >
                            <div
                                class="title"
                                style="float:left; font-size:75%; padding-right:5px; width:60px;"
                            >
                                受注金額
                            </div>
                            {{ d.salesorder_amount | amount }}
                        </div>
                        <div
                            style="clear:both;border-bottom:1px solid var(--border-weak-color);"
                        >
                            <div
                                style="float:left; font-size:60%;padding-top:5px; padding-right:5px; width:60px;"
                            >
                                発注金額
                            </div>
                            <span
                                style="font-size:75%;letter-spacing: 1.75px;"
                                >{{
                                    d.salesorder_purchaseorder_amount
                                        | amount(0)
                                }}</span
                            >
                        </div>
                        <div
                            style="clear:both;border-bottom:1px solid var(--border-weak-color);"
                        >
                            <div
                                style="float:left; font-size:60%;padding-top:5px; padding-right:5px; width:60px;"
                            >
                                内部売上
                            </div>
                            <span
                                style="font-size:75%;letter-spacing: 1.75px;"
                                >{{
                                    (d.salesorder_amount -
                                        d.salesorder_purchaseorder_amount)
                                        | amount
                                }}</span
                            >
                        </div>
                    </div>
                    <div class="item" v-if="isOpened(d)">
                        <MopSubControlMenu
                            customlayout
                            :id="'salesordersubcontrol' + d.salesorder_id"
                        >
                            <div style="width:300px; text-align:center;">
                                <MopEditButton @click.stop="editData(d)" />
                                <MopCopyButton @click.stop="copyData(d)" />
                                <MopLogButton @click.stop="logData(d)" />

                                <div style="text-align:left;">
                                    <div
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        作成 ：
                                        {{ d.create_timestamp | datetime }}
                                        {{ d.create_account_id }}
                                    </div>
                                    <div
                                        v-if="d.update_timestamp"
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        変更 ：
                                        {{ d.update_timestamp | datetime }}
                                        {{ d.update_account_id }}
                                    </div>
                                </div>
                                <div class="subcon-deletebutton">
                                    <MopDeleteButton
                                        @click.stop="deleteSo(d)"
                                        :disabled="
                                            d.sls_exists_rec == '1' ||
                                                d.cos_exists_rec == '1'
                                        "
                                    />
                                </div>
                            </div>
                        </MopSubControlMenu>
                    </div>
                </div>
                <!-- <transition name="accordion-1"> -->
                <div class="op-so-row-others" v-if="isOpened(d)">
                    <div
                        class="content can-block"
                        v-if="d.salesorder_status == 'Z'"
                    >
                        <div class="item can-div">
                            <div class="title">
                                取消理由
                            </div>
                            <div class="value">
                                {{ viewDiv("can", d.cancellation_div) }}
                            </div>
                        </div>
                        <div class="item can-note">
                            <div class="title">取消理由備考</div>
                            <div class="value">
                                {{ d.cancellation_note }}
                            </div>
                        </div>
                    </div>
                    <div class="content others-block">
                        <div class="item est-num">
                            <div class="title">見積書番号</div>
                            <div class="value">
                                {{ d.estimate_number }}
                            </div>
                        </div>
                        <div class="item ord-num">
                            <div class="title">注文番号</div>
                            <div class="value">
                                {{ d.order_number }}
                            </div>
                        </div>
                        <div class="item so-emp">
                            <div class="title">
                                受注担当者<span class="code">{{
                                    d.salesorder_employee_id
                                }}</span>
                            </div>
                            <div class="value">
                                {{ d.salesorder_employee_name_long }}
                            </div>
                        </div>
                        <div class="item so-date">
                            <div class="title">
                                受注日
                            </div>
                            <div class="value">
                                {{ d.salesorder_date | date }}
                            </div>
                        </div>
                        <div class="item acpt-date">
                            <div class="title">
                                検収日
                            </div>
                            <div class="value">
                                {{ d.acceptance_date | date }}
                            </div>
                        </div>
                        <div class="item dep-date">
                            <div class="title">
                                入金日
                            </div>
                            <div class="value">
                                {{ d.deposit_date | date }}
                            </div>
                        </div>
                        <div class="item amount tax">
                            <div class="title">
                                消費税率
                            </div>
                            <div class="value">{{ d.tax_rate | amount }}%</div>
                        </div>
                        <div class="item inv-num">
                            <div class="title">
                                請求書番号
                            </div>
                            <div class="value">
                                {{ d.invoice_number }}
                            </div>
                        </div>
                        <div class="item so-note">
                            <div class="title">
                                受注備考
                            </div>
                            <div class="value">{{ d.salesorder_note }}</div>
                        </div>
                    </div>

                    <div
                        :class="[
                            'sls-panel',
                            slsLoading(d) ? 'panel-loading' : 'panel-loaded'
                        ]"
                    >
                        <SalesList
                            :soid="d.salesorder_id"
                            :so="d"
                            :op="op"
                            @beginLoad="() => slsLoading(d, true)"
                            @endLoad="() => slsLoading(d, false)"
                        ></SalesList>
                    </div>
                    <div
                        :class="[
                            'po-panel',
                            poLoading(d) ? 'panel-loading' : 'panel-loaded'
                        ]"
                    >
                        <PurchaseorderList
                            :soid="d.salesorder_id"
                            :poid="poid"
                            :so="d"
                            :op="op"
                            @beginLoad="() => poLoading(d, true)"
                            @endLoad="() => poLoading(d, false)"
                            :first="first"
                        ></PurchaseorderList>
                    </div>
                </div>
                <!-- </transition> -->
            </div>
        </div>
    </div>
</template>

<script>
import PurchaseorderList from "@/views/opportunity/PurchaseorderList.vue";
import SalesList from "@/views/opportunity/SalesList.vue";
import MopAddButton from "@/components/buttons/MopAddButton.vue";
import MopLogButton from "@/components/buttons/MopLogButton.vue";
import MopEditButton from "@/components/buttons/MopEditButton.vue";
import MopCopyButton from "@/components/buttons/MopCopyButton.vue";
import MopDeleteButton from "@/components/buttons/MopDeleteButton.vue";
import MopSubControlMenu from "@/components/MopSubControlMenu.vue";

export default {
    name: "SalesorderList",
    props: {
        opid: [String, Number],
        soid: [String, Number],
        poid: [String, Number],
        op: Object
    },
    components: {
        PurchaseorderList,
        SalesList,
        MopAddButton,
        MopLogButton,
        MopEditButton,
        MopCopyButton,
        MopDeleteButton,
        MopSubControlMenu
    },
    data: () => ({
        d: {},
        poLoadingList: [],
        slsLoadingList: [],
        payload_data: [],
        toggled: false,
        first: false,
        selectedDataList: []
    }),
    watch: {
        soid: function(value) {
            if (value) {
                for (let d of this.payload_data) {
                    if (d.salesorder_id == value) {
                        this.pushSelected(value);
                        break;
                    }
                }
            }
        },
        opid: function() {
            this.load();
        }
    },
    methods: {
        slsLoading: function(d, flag) {
            const soid = d.salesorder_id;
            let ret = this.findValueList(this.slsLoadingList, soid);
            if (flag === false && ret) {
                this.slsLoadingList = this.slsLoadingList.filter(
                    v => v != soid
                );
            } else if (flag === true && !ret) {
                this.slsLoadingList.push(soid);
            }
            return ret;
        },
        poLoading: function(d, flag) {
            const soid = d.salesorder_id;
            let ret = this.findValueList(this.poLoadingList, soid);
            if (flag === false && ret) {
                this.poLoadingList = this.poLoadingList.filter(v => v != soid);
            } else if (flag === true && !ret) {
                this.poLoadingList.push(soid);
            }
            return ret;
        },
        logData: function(d) {
            let state = this.$store.state.opportunityState.salesorderLog;
            state.model = { ...d };
            state.show = true;
        },
        logSo: function() {
            let state = this.$store.state.opportunityState.salesorderLog;
            state.model = this.op;
            state.show = true;
        },
        createData: function() {
            let state = this.$store.state.opportunityState.salesorderEditor;
            state.title = "受注登録";
            state.model = {};
            state.op = this.op;
            state.updated = this.afterCreate;
            state.mode = "create";
            state.show = true;
        },
        copyData: function(d) {
            let md = { ...d };
            md.opportunity_id = null;
            md.estimate_number = null;
            let state = this.$store.state.opportunityState.salesorderEditor;
            state.title = "受注登録";
            state.model = md;
            state.op = this.op;
            state.updated = this.afterCreate;
            state.mode = "create";
            state.show = true;
        },
        deleteSo: function(d) {
            let state = this.$store.state.opportunityState.confirmDialog;
            state.title = "受注削除前の確認";
            state.icon = "warn";
            state.confirm = [
                "受注（ID : " +
                    d.salesorder_id +
                    "）を削除してもよろしいですか？",
                "",
                {
                    text: "※ 該当受注の売上および発注も削除します。",
                    class: "warn"
                }
            ];
            state.complete = "削除しました。";
            state.okfunction = async func => {
                await this.deleteData(
                    this.makeurl("so", d.salesorder_id),
                    d,
                    func
                );
            };
            state.afterfunction = ok => {
                if (ok) {
                    this.routeReplace({
                        name: "Opportunity",
                        params: { opid: d.opportunity_id }
                    });
                    this.$store.state.opportunityState.refreshNumber++;
                }
            };
            state.show = true;
        },
        editData: function(d) {
            // this.selectedData = d;
            // this.showEdit = true;
            let state = this.$store.state.opportunityState.salesorderEditor;
            state.title = "受注修正 (ID : " + d.salesorder_id + ")";
            state.model = d;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "update";
            state.show = true;
        },
        afterCreate: function(d) {
            let ps = { opid: this.op.opportunity_id };
            ps["soid"] = d.salesorder_id;
            this.routePush({
                name: "Opportunity",
                params: ps
            });
            // this.selectedData = d;
            this.pushSelected(d.salesorder_id);
            return this.load();
        },
        isOpened: function(d) {
            return this.selectedData(d);
        },
        selectedData: function(d, flag) {
            let soid = d.salesorder_id;
            let ret = this.findValueList(this.selectedDataList, soid);
            if (flag === false && ret) {
                this.selectedDataList = this.selectedDataList.filter(
                    v => v != soid
                );
            } else if (flag === true && !ret) {
                this.selectedDataList.push(soid);
            }
            return ret;
        },
        findValueList: function(alist, value) {
            let ret = false;
            for (let s of alist) {
                ret = s == value;
                if (ret) break;
            }
            return ret;
        },
        pushSelected: function(soid) {
            if (!this.findValueList(this.selectedDataList, soid)) {
                this.selectedDataList.push(soid);
            }
        },
        toggleSelectData: function(d) {
            this.toggled = true;
            let soid = d.salesorder_id;
            if (this.findValueList(this.selectedDataList, soid)) {
                this.selectedDataList = this.selectedDataList.filter(
                    v => v != soid
                );
            } else {
                this.selectedDataList.push(soid);
            }
        },
        viewDiv: function(divkey, code) {
            let ret = code;
            let div = this.divs[divkey][code];
            if (div != null) {
                ret += " : " + div.contentshort;
            }
            return ret;
        },
        divName: function(divkey, code, short) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                if (short) {
                    ret = div.contentshort;
                } else {
                    ret = div.contentlong;
                }
            }
            return ret;
        },
        backlist: function() {
            this.routePush({
                name: "Opportunity",
                params: { opid: this.opid }
            });
        },
        refresh: function(dt) {
            let soid = dt.salesorder_id;
            this.pushSelected(soid);
            let ps = { opid: this.op.opportunity_id };
            ps["soid"] = soid;
            this.routePush({
                name: "Opportunity",
                params: ps
            });
        },
        load: function() {
            return this.readDataToArray(
                this.makeurl("so", { op: this.opid }),
                list => (this.payload_data = list)
                // ,
                // obj => {
                //     if (
                //         this.selectedData &&
                //         obj.salesorder_id == this.selectedData.salesorder_id
                //     ) {
                //         this.selectedData = obj;
                //     }
                // }
            );
        }
    },
    updated: function() {
        // if (this.toggled && this.soid) {
        if (this.first && this.soid && !this.poid) {
            let ele = document.getElementById("salesorder" + this.soid);
            if (ele) {
                let rect = ele.getBoundingClientRect();
                let y = rect.top + window.pageYOffset - 128; // TODO : Why 60
                scrollTo(0, y);
            }
        }
        this.first = false;
        this.toggled = false;
    },
    created: async function() {
        this.d.opportunity_id = this.opid;
        if (this.soid) {
            this.pushSelected(this.soid);
        }
        this.first = true;
        await this.loaddivs("sosts", "invissued", "dep", "can");
        await this.load();
    }
};
</script>

<style scoped>
.op-so-list {
    padding: 0px;
    position: relative;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    border-radius: 4px;
}
.op-so-list .op-so-row {
    padding: 0px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 2px;
    background-color: var(--main-context-bg-color);
}
.op-so-list .op-so-row-top {
    padding: 3px 10px 0 10px;
    display: grid;
    position: -webkit-sticky;
    position: sticky;
    top: 128px;
    z-index: 2;
    width: 100%;
    height: 5rem;
    /* background-color: var(--glass-pane-bg-color); */
    background-color: var(--modest-bg-color);
    grid-template-columns: 30px 1fr 120px 80px 60px 60px 210px 40px;
    /* grid-row-gap: 10px; */
    grid-column-gap: 0px;
    overflow: visible;
    border-radius: 4px;
}
.op-so-list .op-so-row:nth-child(2n + 1) .op-so-row-top {
    background-color: var(--modest-odd-bg-color);
}

.op-so-list .op-so-row-top .item {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 5px;
    /* grid-template-columns: 300px 1fr; */
}
.op-so-list .op-so-row-top .item:first-child {
    margin-left: 0px;
    margin-right: 0px;
    cursor: pointer;
    text-align: center;
}
.op-so-list .op-so-row-others {
    padding: 0px 0px 0px 0px;
    border-radius: 4px;
}
.op-so-list .op-so-row-others .content {
    padding: 0px 10px 0px 0px;
    border-radius: 4px;
}

.op-so-list .op-so-row .item {
    padding: 3px 0;
}
.op-so-list .op-so-row .item .value .id {
    font-size: 0.75em;
    display: block;
}
.op-so-list .op-so-row .item .value .id:after {
    content: "";
}
/* tbody:nth-child(2n + 1) {
    background: rgba(255, 215, 128, 0.125);
} */

/* --- */
.op-so-list .op-so-row-others .can-block {
    display: grid;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 120px 1fr;
    grid-template-areas: "can-div   can-note";
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    padding-left: 20px;
}
.op-so-list .can-div {
    grid-area: can-div;
}
.op-so-list .can-note {
    grid-area: can-note;
}

.op-so-list .op-so-row-others .others-block {
    display: grid;
    width: 100%;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 100px 100px 100px 100px 100px 1fr;
    grid-template-areas:
        "est-num   est-num  est-num  est-num   est-num  so-note"
        "so-date   ord-num   ord-num  so-emp   so-emp   so-note"
        "acpt-date inv-num  inv-num  dep-date  tax    so-note ";
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    padding-left: 20px;
}
.op-so-list .est-ver {
    grid-area: est-ver;
}
.op-so-list .est-ver .value {
    text-align: center;
}
.op-so-list .est-num {
    grid-area: est-num;
}
.op-so-list .ord-num {
    grid-area: ord-num;
}
.op-so-list .so-date {
    grid-area: so-date;
}
.op-so-list .so-emp {
    grid-area: so-emp;
}
.op-so-list .acpt-date {
    grid-area: acpt-date;
}
.op-so-list .dep-date {
    grid-area: dep-date;
}
.op-so-list .inv-num {
    grid-area: inv-num;
}
.op-so-list .so-note {
    grid-area: so-note;
}
.op-so-list .tax {
    grid-area: tax;
}
.op-so-list .so-note .title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.op-so-list .so-note .value {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow-y: auto;
    height: 200px;
    border: 1px solid var(--border-weak-color);
    line-height: 1.6em;
    padding: 10px;
}
.op-so-list .sysinfo {
    grid-area: sys;
    justify-self: right;
}
.sysinfo .item {
    display: inline-block;
    margin-left: 20px;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
}

/* --- */

.op-so-row {
    /* border-bottom: 1px solid var(--border-weak-color); */
    z-index: 0;
    border-radius: 4px;
}

.op-so-list .opened-so {
    box-shadow: var(--selected-shadow);
    z-index: 1;
    /* border: 1px solid red; */
    /* border-bottom-style: none; */
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
    border-radius: 4px;
}
.op-so-list .op-so-row.opened-so .op-so-row-top {
    border-top: 1px solid var(--border-weak-color);
    border-bottom: 2px dashed var(--border-weak-color);
    /* filter: drop-shadow(0px 2px 2px #ccc); */
    margin-bottom: 10px;
    /* box-shadow: var(--panel-shadow); */
    border-radius: 4px;
    background-color: var(--main-context-bg-color);
}
/* .opened-so .op-so-row-others {
    box-shadow: 0 0 8px gray;
     border: 1px solid red;
    border-top-style: none;
} */

.salesorder-panel {
    position: relative;
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: 60px 1fr;
    background-color: var(--title-bg-color);
    color: var(--title-text-color);
}
.salesorder-panel > div:first-child {
    width: 60px;
}
.salesorder-panel .panel-header {
    position: -webkit-sticky;
    position: sticky;
    top: 8rem;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--glass-pane-gb-color);
    text-align: center;
}
.salesorder-panel h2 {
    font-weight: normal;
    font-size: 1.2em;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
}

.statusValue {
    display: block;
}

/* ---------------- */
.accordion-1-enter,
.accordion-1-leave-to {
    opacity: 0;
}

@keyframes bounce-in {
    0% {
        transform: scale(1, 0);
    }
    50% {
        transform: scale(1, 0.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

/* ------ */
.sls-panel {
    position: relative;
    margin: 20px 10px 20px 20px;
    padding: 10px 0;
    /* box-shadow: var(--panel-shadow); */
    border: 2px solid var(--border-color);
    border-radius: 4px;
}
/* .sls-panel::before,
.sls-panel::after {
    border-radius: 4px;
    box-shadow: var(--block-shadow);
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
}
.sls-panel::before {
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
    z-index: -1;
}
.sls-panel::after {
     background-color: rgb(248, 221, 211);
    top: 5px;
    left: 5px;
    z-index: -2;
} */

/* ------ */
.po-panel {
    background-color: var(--main-bg-color);
}
.panel-loading {
    /* transform: scaleY(0); */
    /* transition: transform 0.2s; */
    /* display: none; */
    opacity: 0;
    transition: all 0.2s;

    /* height: 0; */
    /* transition: height 0.2s; */
}
.panel-loaded {
    /* transform: scaleY(1); */
    /* transition: transform 0.3s; */
    /* display: block; */
    opacity: 1;
    transition: all 0.4s;
    /* height: auto; */
    /* transition: height 0.3s; */
}
.title .code {
    padding-left: 8px;
    color: var(--text-weak-color);
}
.title .code::before {
    content: " : ";
}
.title .code::after {
    content: "";
}
.value .soid {
    display: block;
    font-size: 80%;
    padding-bottom: 5px;
}
</style>

<template>
    <div class="purchaseorder-panel">
        <div>
            <div class="panel-header">
                <h3>
                    発注
                </h3>
                <MopAddButton @click="openPost" />
                <MopLogButton @click="logPo" />
            </div>
        </div>
        <div class="op-po-list" v-if="payload_data && payload_data.length > 0">
            <div
                v-for="d in payload_data"
                :key="d.purchaseorder_id"
                :class="['op-po-row', { 'opened-po': isOpened(d) }]"
                :id="'purchaseorder' + d.purchaseorder_id"
            >
                <div class="op-po-row-top content">
                    <div class="item" @click="toggleSelectData(d)">
                        <span v-if="isOpened(d)">▼</span>
                        <span v-else>▶</span>
                    </div>
                    <div class="item">
                        <!-- <div class="title">発注</div> -->
                        <div class="value">
                            <span class="poid"
                                >発注 : {{ d.purchaseorder_id }}</span
                            >
                            {{ d.purchaseorder_subject }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">納品年月</div>
                        <div class="value">
                            {{ d.delivery_date | ymjp }}
                        </div>
                    </div>
                    <div class="item">
                        <!-- <div class="title">発注ステータス</div>
                        <div class="value">
                            {{ divname("posts", d.purchaseorder_status) }}
                        </div> -->
                        <div class="title">ステータス</div>
                        <div class="value">
                            <div class="statusValue">
                                {{ d.purchaseorder_status }}
                                <div class="statusName">
                                    {{
                                        divName("posts", d.purchaseorder_status)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">区分</div>
                        <!-- 発注区分 -->
                        <div class="divValue">
                            {{ divName("po", d.purchaseorder_div, true) }}
                            <div class="divName">
                                {{ divName("po", d.purchaseorder_div, false) }}
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">
                            発注先<span
                                class="code"
                                :title="d.purchaseorder_customer_id"
                                >{{ d.purchaseorder_customer_code }}</span
                            >
                        </div>

                        <div class="value">
                            {{ d.purchaseorder_customer_name_short }}
                            <small>{{
                                d.purchaseorder_customer_name_long
                            }}</small>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">支払</div>
                        <div class="divValue">
                            {{ divName("pmt", d.payment_div, true) }}
                            <div class="divName">
                                {{ divName("pmt", d.payment_div, false) }}
                            </div>
                        </div>
                    </div>
                    <div class="item amount">
                        <div class="title">発注金額</div>
                        <div class="value">
                            {{ d.purchaseorder_amount | amount }}
                        </div>
                    </div>

                    <div class="item" v-if="isOpened(d)">
                        <MopSubControlMenu
                            customlayout
                            :id="'purchaseordersubcontrol' + d.purchaseorder_id"
                        >
                            <div style="width:250px; text-align:center;">
                                <MopEditButton @click.stop="editData(d)" />
                                <MopCopyButton @click.stop="copyData(d)" />
                                <MopLogButton @click.stop="logData(d)" />
                                <div style="text-align:left;">
                                    <div
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        作成 ：
                                        {{ d.create_timestamp | datetime }}
                                        {{ d.create_account_id }}
                                    </div>
                                    <div
                                        v-if="d.update_timestamp"
                                        style="font-size:0.75rem;padding-top:0.75em;"
                                    >
                                        変更 ：
                                        {{ d.update_timestamp | datetime }}
                                        {{ d.update_account_id }}
                                    </div>
                                </div>
                                <div class="subcon-deletebutton">
                                    <MopDeleteButton
                                        @click.stop="deletePo(d)"
                                        :disabled="d.exists_rec == '1'"
                                    />
                                </div>
                            </div>
                        </MopSubControlMenu>
                    </div>
                </div>
                <!-- <mop-accordion closest=".op-so-row-others"> -->
                <div
                    class="op-po-row-others accordion-target"
                    v-if="isOpened(d)"
                >
                    <div class="content">
                        <div class="item po-date">
                            <div class="title">発注日</div>
                            <div class="value">
                                {{ d.purchaseorder_date | date }}
                            </div>
                        </div>
                        <div class="item ord-num">
                            <div class="title">発注書番号</div>
                            <div class="value">{{ d.order_number }}</div>
                        </div>
                        <div class="item po-emp">
                            <div class="title">
                                発注担当者<span class="code">{{
                                    d.purchaseorder_employee_id
                                }}</span>
                            </div>
                            <div class="value">
                                {{ d.purchaseorder_employee_name_long }}
                            </div>
                        </div>

                        <div class="item dlv-date">
                            <div class="title">納品日</div>
                            <div class="value">
                                {{ d.delivery_date | date }}
                            </div>
                        </div>

                        <div class="item inv-rcpt">
                            <div class="title">請求書受領</div>
                            <div class="divValue">
                                {{
                                    divName(
                                        "invrcpt",
                                        d.invoice_receipt_div,
                                        true
                                    )
                                }}
                                <div class="divName">
                                    {{
                                        divName(
                                            "invrcpt",
                                            d.invoice_receipt_div,
                                            false
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="item inv-num">
                            <div class="title">請求書番号</div>
                            <div class="value">{{ d.invoice_number }}</div>
                        </div>
                        <div class="item pmt-date">
                            <div class="title">支払日</div>
                            <div class="value">{{ d.payment_date | date }}</div>
                        </div>
                        <div class="item amount tax">
                            <div class="title">消費税率</div>
                            <div class="value">{{ d.tax_rate | amount }}%</div>
                        </div>
                        <div class="item po-note">
                            <div class="title">発注備考</div>
                            <div class="value">{{ d.purchaseorder_note }}</div>
                        </div>
                    </div>

                    <div
                        :class="[
                            'cos-panel',
                            cosLoading(d) ? 'panel-loading' : 'panel-loaded'
                        ]"
                    >
                        <CostofsalesList
                            :po="d"
                            :so="so"
                            @beginLoad="() => cosLoading(d, true)"
                            @endLoad="() => cosLoading(d, false)"
                        ></CostofsalesList>
                    </div>
                </div>
                <!-- </mop-accordion> -->
            </div>
        </div>
    </div>
</template>

<script>
// import PurchaseorderEditor from "@/views/PurchaseorderEditor.vue";
import CostofsalesList from "@/views/opportunity/CostofsalesList.vue";
import MopAddButton from "@/components/buttons/MopAddButton.vue";
import MopLogButton from "@/components/buttons/MopLogButton.vue";
import MopEditButton from "@/components/buttons/MopEditButton.vue";
import MopCopyButton from "@/components/buttons/MopCopyButton.vue";
import MopDeleteButton from "@/components/buttons/MopDeleteButton.vue";
import MopSubControlMenu from "@/components/MopSubControlMenu.vue";
// import MopAccordion from "@/components/MopAccordion.vue";

export default {
    name: "PurchaseOrderList",
    props: {
        op: Object,
        so: Object,
        poid: [String, Number],
        first: Boolean
    },
    components: {
        // PurchaseorderEditor,
        CostofsalesList,
        MopAddButton,
        MopLogButton,
        MopEditButton,
        MopCopyButton,
        MopDeleteButton,
        MopSubControlMenu
        // MopAccordion
    },
    data: function() {
        return {
            selectedDataList: [],
            d: {},
            payload_data: null,
            toggled: false,
            initFlag: this.first,
            cosLoadingList: []
        };
    },
    watch: {
        poid: function(value) {
            if (value) {
                for (let d of this.payload_data) {
                    if (d.purchaseorder_id == value) {
                        this.pushSelected(value);
                        break;
                    }
                }
            }
        }
    },
    methods: {
        cosLoading: function(d, flag) {
            const poid = d.purchaseorder_id;
            let ret = this.findValueList(this.cosLoadingList, poid);
            if (flag === false && ret) {
                this.cosLoadingList = this.cosLoadingList.filter(
                    v => v != poid
                );
            } else if (flag === true && !ret) {
                this.cosLoadingList.push(poid);
            }
            return ret;
        },
        logData: function(d) {
            let state = this.$store.state.opportunityState.purchaseorderLog;
            state.model = {
                opportunity_id: this.op.opportunity_id,
                salesorder_id: d.salesorder_id,
                purchaseorder_id: d.purchaseorder_id
            };
            state.show = true;
        },
        logPo: function() {
            let state = this.$store.state.opportunityState.purchaseorderLog;
            state.model = this.so;
            state.show = true;
        },
        openPost: function() {
            let state = this.$store.state.opportunityState.purchaseorderEditor;
            state.title = "発注登録";
            state.model = {};
            state.so = this.so;
            state.op = this.op;
            state.updated = this.afterCreate;
            state.mode = "create";
            state.show = true;
        },
        copyData: function(d) {
            let state = this.$store.state.opportunityState.purchaseorderEditor;
            state.title = "発注登録";
            state.model = { ...d };
            state.so = this.so;
            state.op = this.op;
            state.updated = this.afterCreate;
            state.mode = "create";
            state.show = true;
        },
        deletePo: function(d) {
            let state = this.$store.state.opportunityState.confirmDialog;
            state.title = "発注削除前の確認";
            state.icon = "warn";
            state.confirm = [
                "発注（ID : " +
                    d.purchaseorder_id +
                    "）を削除してもよろしいですか？",
                "",
                { text: "※ 該当発注の原価も削除します。", class: "warn" }
            ];
            state.complete = "削除しました。";
            state.okfunction = async func => {
                await this.deleteData(
                    this.makeurl("po", d.purchaseorder_id),
                    d,
                    func
                );
            };
            state.afterfunction = ok => {
                if (ok) {
                    this.routeReplace({
                        name: "Opportunity",
                        params: {
                            opid: this.op.opportunity_id,
                            soid: this.so.salesorder_id
                        }
                    });
                    this.$store.state.opportunityState.refreshNumber++;
                }
            };
            state.show = true;
        },
        editData: function(d) {
            let state = this.$store.state.opportunityState.purchaseorderEditor;
            state.title = "発注修正 (ID : " + d.purchaseorder_id + ")";
            state.model = d;
            state.so = this.so;
            state.op = this.op;
            state.updated = this.refresh;
            state.mode = "update";
            state.show = true;
        },
        afterCreate: function(d) {
            let ps = { opid: this.op.opportunity_id };
            ps["soid"] = this.so.salesorder_id;
            ps["poid"] = d.purchaseorder_id;
            this.routePush({
                name: "Opportunity",
                params: ps
            });
            this.pushSelected(d.purchaseorder_id);
            return this.load();
        },
        isOpened: function(d) {
            return this.selectedData(d);
        },
        selectedData: function(d, flag) {
            let poid = d.purchaseorder_id;
            let ret = this.findValueList(this.selectedDataList, poid);
            if (flag === false && ret) {
                this.selectedDataList = this.selectedDataList.filter(
                    v => v != poid
                );
            } else if (flag === true && !ret) {
                this.selectedDataList.push(poid);
            }
            return ret;
        },
        findValueList: function(alist, value) {
            let ret = false;
            for (let s of alist) {
                ret = s == value;
                if (ret) break;
            }
            return ret;
        },
        pushSelected: function(poid) {
            if (!this.findValueList(this.selectedDataList, poid)) {
                this.selectedDataList.push(poid);
            }
        },
        toggleSelectData: function(d) {
            this.toggled = true;
            let poid = d.purchaseorder_id;
            if (this.findValueList(this.selectedDataList, poid)) {
                this.selectedDataList = this.selectedDataList.filter(
                    v => v != poid
                );
            } else {
                this.selectedDataList.push(poid);
            }
            // this.toggled = true;
            // let ps = {
            //     opid: this.so.opportunity_id,
            //     soid: this.so.salesorder_id
            // };
            // if (
            //     this.selectedData &&
            //     this.selectedData.purchaseorder_id == d.purchaseorder_id
            // ) {
            //     this.selectedData = null;
            // } else {
            //     this.selectedData = d;
            //     ps["poid"] = d.purchaseorder_id;
            // }
            // this.$router.push({
            //     name: "Opportunity",
            //     params: ps
            // });
        },
        divName: function(divkey, code, short) {
            let ret = "";
            let div = this.divs[divkey][code];
            if (div != null) {
                if (short) {
                    ret = div.contentshort;
                } else {
                    ret = div.contentlong;
                }
            }
            return ret;
        },
        refresh: async function(d) {
            this.$emit("beginLoad");
            // this.payload_data = [];
            // this.selectedData = dt;
            let ps = { opid: this.op.opportunity_id };
            ps["soid"] = this.so.salesorder_id;
            ps["poid"] = d.purchaseorder_id;
            this.routePush({
                name: "Opportunity",
                params: ps
            });
            this.pushSelected(d.purchaseorder_id);
            await this.load();
            // await this.$nextTick();
        },
        load: function() {
            return this.readDataToArray(
                this.makeurl("po", { so: this.so.salesorder_id }),
                list => {
                    this.payload_data = list;
                    this.so.polist = list;
                }
                // ,
                // obj => {
                //     if (
                //         this.selectedData &&
                //         obj.purchaseorder_id ==
                //             this.selectedData.purchaseorder_id
                //     ) {
                //         this.selectedData = obj;
                //     }
                // }
            );
        }
    },
    updated: function() {
        if (this.initFlag && this.poid) {
            let ele = document.getElementById("purchaseorder" + this.poid);
            if (ele) {
                let rect = ele.getBoundingClientRect();
                let y = rect.top + window.pageYOffset - 208; // TODO : Why 110
                scrollTo(0, y);
            }
        }
        this.toggled = false;
        this.initFlag = false;
        this.$emit("endLoad", this.payload_data);
        // this.$emit("endLoad", this.so.polist);
    },
    created: async function() {
        this.$emit("beginLoad");
        this.d.salesorder_id = this.so.salesorder_id;
        await this.loaddivs("posts", "po", "invrcpt", "pmt");
        if (this.poid) {
            this.pushSelected(this.poid);
        }
        // this.initFlag = true;
        if (!this.so.polist) {
            await this.load();
        } else {
            this.payload_data = this.so.polist;
            this.$emit("endLoad");
        }
        // await this.$nextTick();
        // this.$emit("endLoad");
    }
};
</script>

<style scoped>
.op-po-list {
    padding: 0;
    position: relative;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    border-radius: 4px;
}
.op-po-list .op-po-row {
    padding: 0px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 2px;
    background-color: var(--main-context-bg-color);
}
.op-po-list .op-po-row-top {
    padding: 3px 10px 0 10px;
    display: grid;
    position: -webkit-sticky;
    position: sticky;
    top: 208px;
    z-index: 1;
    background-color: var(--modest-bg-color);
    grid-template-columns: 30px 2fr 110px 80px 80px 1fr 60px 130px 40px;
    /* grid-row-gap: 10px; */
    grid-column-gap: 0px;
    border-radius: 4px;
}

.op-po-list .op-po-row:nth-child(2n + 1) .op-po-row-top {
    background-color: var(--modest-odd-bg-color);
}

.op-po-list .op-po-row-top .item {
    display: inline-block;
    vertical-align: top;
    /* grid-template-columns: 300px 1fr; */
    margin-left: 5px;
    margin-right: 5px;
}
.op-po-list .op-po-row-top .item:first-child {
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
}

.statusValue {
    display: block;
}

.op-po-list .op-po-row-others > .content {
    display: grid;
    width: 100%;
    /* grid-template-rows: 60px 60px; */
    grid-template-columns: 100px 100px 100px 80px 100px 60px 1fr;
    grid-template-areas:
        "po-date     ord-num     ord-num     po-emp    po-emp    po-emp po-note"
        "dlv-date    inv-rcpt    inv-num     inv-num   pmt-date  tax    po-note";
    grid-row-gap: 5px;
    grid-column-gap: 10px;
    padding-left: 10px;
    border-radius: 4px;
}

.op-po-list .po-date {
    grid-area: po-date;
}
.op-po-list .ord-ver {
    grid-area: ord-ver;
}
.op-po-list .ord-num {
    grid-area: ord-num;
}
.op-po-list .po-emp {
    grid-area: po-emp;
}
.op-po-list .dlv-date {
    grid-area: dlv-date;
}
.op-po-list .inv-rcpt {
    grid-area: inv-rcpt;
}
.op-po-list .inv-num {
    grid-area: inv-num;
}
.op-po-list .pmt-date {
    grid-area: pmt-date;
}
.op-po-list .tax {
    grid-area: tax;
}
.op-po-list .po-note {
    grid-area: po-note;
}
.op-po-list .po-note .title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.op-po-list .po-note .value {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    overflow-y: auto;
    height: 120px;
    border: 1px solid var(--border-weak-color);
    line-height: 1.5em;
    padding: 6px;
}

.op-po-list .sysinfo {
    grid-area: sys;
    justify-self: right;
}

.sysinfo .item {
    display: inline-block;
    margin-left: 20px;
}
.sysinfo .item .title {
    display: inline-block;
    vertical-align: top;
}
.sysinfo .item .title:after {
    content: " : ";
}
.sysinfo .item .value {
    display: inline-block;
}

.op-po-list .op-po-row .item {
    padding: 3px 0;
}
.op-po-list .op-po-row .item .value .id {
    font-size: 0.75em;
    display: block;
}
.op-po-list .op-po-row .item .value .id:after {
    content: "";
}
/* tbody:nth-child(2n + 1) {
    background: rgba(255, 215, 128, 0.125);
} */

.op-po-row {
    border-bottom: 1px solid var(--border-color);
    z-index: 0;
}

.opened-po {
    /* box-shadow: var(--selected-shadow); */
    z-index: 1;
    border-radius: 4px;
    /* border: 1px solid red; */
    /* border-bottom-style: none; */
}

.op-po-list .op-po-row.opened-po .op-po-row-top {
    border-top: 1px solid var(--border-weak-color);
    border-bottom: 2px dashed var(--border-weak-color);
    /* filter: drop-shadow(0px 2px 2px #ccc); */
    margin-bottom: 10px;
    /* box-shadow: var(--panel-shadow); */
    border-radius: 4px;
    background-color: var(--main-context-bg-color);
}

.opened-po .op-po-row-others {
    padding: 0px 10px 10px 10px;
    border-radius: 4px;
}

.purchaseorder-panel {
    position: relative;
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: 60px 1fr;
    /*background-color: var(--title-bg-color);*/
    color: var(--title-text-color);
    padding: 2px 0 10px 0;
}
.purchaseorder-panel > div:first-child {
    width: 60px;
}
.purchaseorder-panel .panel-header {
    position: -webkit-sticky;
    position: sticky;
    top: 210px;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}
.purchaseorder-panel h3 {
    font-weight: normal;
    font-size: 1.2em;
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
}

.cos-panel {
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 4px;
}
/* ------ */
.cos-panel {
    position: relative;
    margin: 20px 10px;
    padding: 10px 0;
    /* box-shadow: var(--panel-shadow); */
    border: 2px solid var(--border-color);
}

.panel-loading {
    opacity: 0;
    transition: all 0.2s;
}
.panel-loaded {
    opacity: 1;
    transition: all 0.4s;
}
.title .code {
    padding-left: 8px;
    color: var(--text-weak-color);
}
.title .code::before {
    content: " : ";
}
.title .code::after {
    content: "";
}
.value .poid {
    display: block;
    font-size: 80%;
    padding-bottom: 5px;
}
/* ------ */
</style>

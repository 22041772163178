<template>
    <div>
        <opportunity-view
            :opid="oppo.opid"
            :soid="oppo.soid"
            :poid="oppo.poid"
            :refreshNumber="state.refreshNumber"
            @loadedOp="loadedOp"
        ></opportunity-view>

        <!-- <div class="compoblock">
            <salesorder-list
                :opid="oppo.opid"
                :op="op"
                :soid="oppo.soid"
                :poid="oppo.poid"
            ></salesorder-list>
        </div> -->

        <!-- <OpModal
            v-if="showCreate"
            @close="showCreate = false"
            title="売上登録"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <SalesEditor
                mode="create"
                :so="so"
                @fin="close"
                @step="step"
                @updated="refresh"
            ></SalesEditor>
        </OpModal> -->

        <OpModal
            v-if="state.opportunityEditor.show"
            :title="state.opportunityEditor.title"
            @close="state.opportunityEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <OpportunityEditor
                v-model="state.opportunityEditor.model"
                :mode="state.opportunityEditor.mode"
                @fin="close"
                @step="step"
                @updated="state.opportunityEditor.updated"
                @moveto="state.opportunityEditor.moveto"
            ></OpportunityEditor>
        </OpModal>

        <OpModal
            v-if="state.salesorderEditor.show"
            :title="state.salesorderEditor.title"
            @close="state.salesorderEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <SalesorderEditor
                v-model="state.salesorderEditor.model"
                :op="state.salesorderEditor.op"
                :mode="state.salesorderEditor.mode"
                @fin="close"
                @step="step"
                @soUpdated="soUpdated"
            ></SalesorderEditor>
        </OpModal>

        <OpModal
            v-if="state.purchaseorderEditor.show"
            :title="state.purchaseorderEditor.title"
            @close="state.purchaseorderEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <PurchaseorderEditor
                v-model="state.purchaseorderEditor.model"
                :so="state.purchaseorderEditor.so"
                :op="state.purchaseorderEditor.op"
                :mode="state.purchaseorderEditor.mode"
                @fin="close"
                @step="step"
                @poUpdated="poUpdated"
            ></PurchaseorderEditor>
        </OpModal>

        <OpModal
            v-if="state.saleslistEditor.show"
            :title="state.saleslistEditor.title"
            @close="state.saleslistEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <SalesListEditor
                :soid="state.saleslistEditor.soid"
                :so="state.saleslistEditor.so"
                :op="state.saleslistEditor.op"
                :data="state.saleslistEditor.data"
                @fin="close"
                @step="step"
                @updated="state.saleslistEditor.updated"
            ></SalesListEditor>
        </OpModal>

        <OpModal
            v-if="state.costofsaleslistEditor.show"
            :title="state.costofsaleslistEditor.title"
            @close="state.costofsaleslistEditor.show = false"
            v-slot="{ close, step }"
            :steps="['入力', '確認', '完了']"
        >
            <CostofsalesListEditor
                :poid="state.costofsaleslistEditor.poid"
                :po="state.costofsaleslistEditor.po"
                :op="state.costofsaleslistEditor.op"
                :data="state.costofsaleslistEditor.data"
                @fin="close"
                @step="step"
                @updated="state.costofsaleslistEditor.updated"
            ></CostofsalesListEditor>
        </OpModal>

        <OpModal
            v-if="state.opportunityLog.show"
            title="案件編集履歴"
            @close="state.opportunityLog.show = false"
            v-slot="{ close }"
        >
            <OpportunityLogList
                v-model="state.opportunityLog.model"
                @fin="close"
            ></OpportunityLogList>
        </OpModal>

        <OpModal
            v-if="state.salesorderLog.show"
            title="受注編集履歴"
            @close="state.salesorderLog.show = false"
            v-slot="{ close }"
        >
            <SalesorderLogList
                v-model="state.salesorderLog.model"
                @fin="close"
            ></SalesorderLogList>
        </OpModal>
        <OpModal
            v-if="state.salesLog.show"
            title="売上編集履歴"
            @close="state.salesLog.show = false"
            v-slot="{ close }"
        >
            <SalesLogList
                v-model="state.salesLog.model"
                @fin="close"
            ></SalesLogList>
        </OpModal>
        <OpModal
            v-if="state.purchaseorderLog.show"
            title="発注編集履歴"
            @close="state.purchaseorderLog.show = false"
            v-slot="{ close }"
        >
            <PurchaseorderLogList
                v-model="state.purchaseorderLog.model"
                @fin="close"
            ></PurchaseorderLogList>
        </OpModal>
        <OpModal
            v-if="state.costofsalesLog.show"
            title="原価編集履歴"
            @close="state.costofsalesLog.show = false"
            v-slot="{ close }"
        >
            <CostofsalesLogList
                v-model="state.costofsalesLog.model"
                @fin="close"
            ></CostofsalesLogList>
        </OpModal>
        <OpModal
            v-if="state.confirmDialog.show"
            :title="state.confirmDialog.title"
            @close="$store.dispatch('initOppoStateConfirm')"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                :confirm="state.confirmDialog.confirm"
                :complete="state.confirmDialog.complete"
                :okfunction="state.confirmDialog.okfunction"
                :icon="state.confirmDialog.icon"
                @after="afterConfirm"
                @fin="close"
                @step="step"
            ></OpConfirm>
        </OpModal>
    </div>
</template>

<script>
import OpportunityView from "./OpportunityView.vue";
import PurchaseorderEditor from "@/views/opportunity/PurchaseorderEditor.vue";
import OpportunityEditor from "@/views/opportunity/OpportunityEditor.vue";
import SalesorderEditor from "@/views/opportunity/SalesorderEditor.vue";
import OpportunityLogList from "@/views/opportunity/OpportunityLogList.vue";
import SalesorderLogList from "@/views/opportunity/SalesorderLogList.vue";
import SalesLogList from "@/views/opportunity/SalesLogList.vue";
import PurchaseorderLogList from "@/views/opportunity/PurchaseorderLogList.vue";
import CostofsalesLogList from "@/views/opportunity/CostofsalesLogList.vue";
import OpConfirm from "@/components/OpConfirm.vue";
import SalesListEditor from "@/views/opportunity/SalesListEditor.vue";
import CostofsalesListEditor from "@/views/opportunity/CostofsalesListEditor.vue";

export default {
    name: "Opportunity",
    props: ["opid", "soid", "poid"],
    data: function() {
        return {
            currentStep: 0,
            // cdialog: {
            //     okbutton: "OK",
            //     cancelbutton: "キャンセル",
            //     message: "",
            //     step: 0,
            //     execfunc: function() {}
            // },
            oppo: {
                opid: 0,
                soid: 0,
                poid: 0
            },
            op: {},
            state: this.$store.state.opportunityState,
            opRefreshNumber: 0
        };
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    components: {
        OpportunityView,
        PurchaseorderEditor,
        OpportunityEditor,
        SalesorderEditor,
        OpportunityLogList,
        SalesorderLogList,
        SalesLogList,
        PurchaseorderLogList,
        CostofsalesLogList,
        OpConfirm,
        SalesListEditor,
        CostofsalesListEditor
    },
    methods: {
        loadedOp: function(opdata) {
            this.op = opdata;
            if (this.oppo.opid != opdata.opportunity_id) {
                this.oppo.opid = opdata.opportunity_id;
            }
        },
        soUpdated: function(val) {
            this.state.salesorderEditor.updated(val);
            this.state.refreshNumber++;
        },
        poUpdated: function(val) {
            this.state.purchaseorderEditor.updated(val);
            this.state.refreshNumber++;
        },
        salesEditorUpdated: function(val) {
            this.state.salesEditor.updated(val);
        },
        afterConfirm: function(ok) {
            this.state.confirmDialog.afterfunction(ok);
        }
    },

    beforeRouteUpdate(to, from, next) {
        if (to.params) {
            let o = this.oppo;
            let p = to.params;
            // if (o.opid != p.opid || o.soid != p.soid || o.poid != p.poid) {
            o.opid = p.opid;
            o.soid = p.soid;
            o.poid = p.poid;
            // }
        }
        console.log(to);
        next();
    },
    created: async function() {
        this.oppo.opid = this.opid;
        if (this.soid != undefined) {
            this.oppo.soid = this.soid;
        } else {
            this.oppo.soid = 0;
        }

        if (this.poid != undefined) {
            this.oppo.poid = this.poid;
        } else {
            this.oppo.poid = 0;
        }
        this.$store.dispatch("initOppoState");
    }
};
</script>

<style scoped>
.compoblock {
    padding: 10px 0px;
    border-top: 1px solid var(--border-color);
}
</style>

<template>
    <button class="actionButton" @click="doClick">
        <div class="mark">
            <IconBase iconName="編集"><IconPencil /></IconBase>
        </div>
        <div class="txt">編集</div>
    </button>
</template>

<script>
import MopButtonMixin from "@/components/buttons/MopButtonMixin.vue";
import IconPencil from "@/components/icons/IconPencil.vue";

export default {
    name: "MopEditButton",
    mixins: [MopButtonMixin],
    components: {
        IconPencil
    }
};
</script>

<template>
    <IconBase
        iconName="済"
        :width="width"
        :height="width"
        v-slot="{ viewbox }"
        :style="zumiStyle()"
    >
        <IconZumi @viewbox="viewbox" />
    </IconBase>
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue";
import IconZumi from "@/components/icons/IconZumi.vue";

export default {
    name: "ZumiStamp",
    components: {
        IconBase,
        IconZumi
    },
    props: {
        width: { type: Number, default: 24 }
    },
    methods: {
        zumiStyle: function() {
            let dtop = Math.random() * this.width * 0.2;
            let dleft = Math.random() * this.width * 0.4;
            let ddeg = Math.random() * 45;
            ddeg -= 30;

            let mtop = this.width * 0.6;
            let mleft = this.width * 0.7;

            let ret = {
                position: "absolute",
                top: "calc(50% - " + mtop + "px + " + dtop + "px)",
                left: "calc(50% - " + mleft + "px + " + dleft + "px)",
                transformOrigin: "center",
                transform: "rotate(" + ddeg + "deg)"
            };
            return ret;
        }
    }
};
</script>
